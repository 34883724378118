import { Pipe, PipeTransform } from "@angular/core";

/**
 *
 */
@Pipe({name: 'formatLengthFile'})
export class FormatLengthFilePipe implements PipeTransform {
    /**
     *
     * @param value
     * @returns {number}
     */
    transform(value: number): String {
      let ret: string = '';
      if(value > 1000000) {
        ret = Math.round(value / 1000000) + ' Mo' //Mo
      } else if ( value >= 1000 && value < 1000000) {
        ret = Math.round(value / 1000) + ' Ko' //Mo
      } else {
        ret = Math.round(value / 1000000) + ' ' //Mo
      }

      return ret;
    }
}