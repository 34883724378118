import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatButtonModule, MatDialogModule, MatGridListModule, MatTooltipModule, MatIconModule, MatNativeDateModule, MatDatepickerModule, MatTableModule, MatSortModule, MatCheckboxModule, MatSlideToggleModule, MatRadioModule } from '@angular/material';

import { AdmHomeComponent } from './adm-home/adm-home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TextMaskModule } from 'angular2-text-mask';
import { HeaderBoComponent } from 'src/app/components/header-bo/header-bo.component';
import { CommonBoModuleModule } from '../common-bo-module/common-bo-module.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    MatGridListModule,
    MatIconModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    TextMaskModule,
    CommonBoModuleModule
  ],
  declarations: [
    AdmHomeComponent
  ]
})
export class AdminModule { }
