import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UploadService } from '../upload.service';
import { DialogComponent, DialogData } from '../dialog/dialog.component';

@Component({
  selector: 'oap-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input('dialogData') dialogData :DialogData;

  constructor(public dialog: MatDialog, 
    public uploadService: UploadService) { }

  ngOnInit() {
    if(this.dialogData) {
      console.log("dialogData maxFile : " + this.dialogData.typeDocument.nbDocumentMax + " - equivalence : " + this.dialogData.typeDocument.equivalence);
    }
  }

  public openUploadDialog(nbFile) {
    let dialogRef = this.dialog.open(DialogComponent, 
            { width: '50%', height: '50%',
              data: { patientKey: this.dialogData.patientKey,  typeDocument: this.dialogData.typeDocument } 
      });
  }
}
