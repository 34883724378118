import { Injectable } from '@angular/core';

import { AppConfigService } from '../../services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  private apiUrl: string;

  constructor(private _http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }

  changePassword(compteKey: number, password: string) {
    const apiUrl = this._appConfig.config.servicesBaseUrl;
    const data = {
      compteKey: compteKey, 
      password: password
    };
    return this._http.post<any>(apiUrl + `/cpt/change-password`, data)
        .pipe(map(c => {
            // login successful if there's a jwt token in the response
            //if (tokens && tokens.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
            //    localStorage.setItem('currentUser', JSON.stringify(tokens));
            //}
            return c;
        }));
  }


}
