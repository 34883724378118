import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Patient} from '../models/patient';
import {AuthToken} from '../models/authToken';
import {PatientService} from './patient.service';
import {isNull} from 'util';
import {DisclaimerService} from './disclaimer.service';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    authToken: AuthToken;
    currentPatient: Patient;
    patientId: string;
    userID: string;

    constructor(
        private disclaimer: DisclaimerService,
        private patientService: PatientService,
        private storageService: StorageService
    ) {
    }

    contact(url: string, label: string) {
        var a = window.document.createElement('a');
        a.href = 'mailto:' + url;
        a.click();

        this.pushDataLayer('Demande de contact', 'Click', label);
    }

    trackingLinks(url: string) {
        this.pushDataLayer('Lien sortant', 'Click', url);
        window.open(url);
    }

    openExternal(url: string) {
        this.pushDataLayer('Lien sortant', 'Click', url);
        this.disclaimer.openPopinExternal(url);
    }


    pushDataLayer(category: string, action: string, label: string) {
        window['dataLayer'].push({
            'category': category,
            'action': action,
            'label': label,
            'event': 'ua_event_ready'
        });
    }

    pushDataLayerDelivery(category: string, action: string, orders: Array<any>) {
        orders.forEach((order, index) => {
            let pushObject = {
                'category': category,
                'action': action,
                'event': 'update_ua_event'
            };
            pushObject['label'] = order.label;
            pushObject['product_detail_order' + (index + 1)] = order.products.join(" | ");
            pushObject['orderID_' + (index + 1)] = order.orderID;
            window['dataLayer'].push(pushObject);
        });
    }

    DataLayer(PageName: string, PageType: string, Category: string, SubCategory: string) {
        this.patientId = '';
        this.userID = '';
        var Env = 'Test';

        if (environment.production) {
            Env = 'prod';
        } else if (environment.preprod) {
            Env = 'preprod';
        }

        this.authToken = JSON.parse(localStorage.getItem('currentUser'));
        if (!isNull(this.authToken)) {
            if (isNaN(parseInt(JSON.parse(localStorage.getItem('patientId'))))) {
                this.patientService.getPatientsByCptKey(this.authToken.id).subscribe(data => {
                        if (!isNull(data[0].identifiantSAP)) {
                            this.patientId = data[0].identifiantSAP.toString();
                        }
                        this.userID = data[0].patientKey.toString();
                        window['dataLayer'].push({
                            'Env': Env,
                            'PageName': PageName,
                            'PageType': PageType,
                            'Category': Category,
                            'SubCategory': SubCategory,
                            'UserID': this.userID,
                            'ProfileID': this.patientId,
                            'event': 'gtm.ready'
                        });
                    }
                );
            }
            else {
                if (!isNull(JSON.parse(localStorage.getItem('patientCurrent')).identifiantSAP)) {
                    this.patientId = JSON.parse(localStorage.getItem('patientCurrent')).identifiantSAP.toString();
                }
                this.userID = JSON.parse(localStorage.getItem('patientId'));

                this.storageService.watchStorage().subscribe((data: string) => {

                    if (!isNull(JSON.parse(localStorage.getItem('patientCurrent')).identifiantSAP)) {

                        this.patientId = JSON.parse(localStorage.getItem('patientCurrent')).identifiantSAP.toString();
                    }
                    this.userID = JSON.parse(localStorage.getItem('patientId'));
                });

                window['dataLayer'].push({
                    'Env': Env,
                    'PageName': PageName,
                    'PageType': PageType,
                    'Category': Category,
                    'SubCategory': SubCategory,
                    'UserID': this.userID,
                    'ProfileID': this.patientId,
                    'event': 'gtm.ready'
                });
            }
        }
        else {
            window['dataLayer'].push({
                'Env': Env,
                'PageName': PageName,
                'PageType': PageType,
                'Category': Category,
                'SubCategory': SubCategory,
                'UserID': this.userID,
                'ProfileID': this.patientId,
                'event': 'gtm.ready'
            });
        }
    }
}
