import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AlertService} from '../../services/alert.service';
import {DateAdapter} from '@angular/material/core';
import {formatDate, DatePipe} from '@angular/common';
import {PatientService} from '../../services/patient.service';
import {Patient} from '../../models/patient';
import {first, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MessageService} from '../../services/message.service';
import {PatientUpdate} from '../../models/patientUpdate';
import {
    OapStandarErrorStateMatcher,
    errorMessages,
    OapErrorMessages,
    regExps,
    CustomValidatorsModule
} from '../../modules/custom-validators/custom-validators.module';
import {Moment} from 'moment';
import {JJMMYYYY_FORMAT_PROVIDER} from '../../helpers/jjmmyyy-format.helper';
import {LoaderService} from '../../services/loader.service';

import {TypeDocumentService} from '../../services/type-document.service';
import {TypeDocument} from '../../models/typeDocument';
import {DialogData} from '../../upload/dialog/dialog.component';
import {DialogDataImpl} from '../../models/dialogDataImpl';
import {UploadService} from '../../upload/upload.service';

import {HeaderComponent} from '../../components/header/header.component';
import {FooterComponent} from '../../components/footer/footer.component';

import { DisclaimerService } from '../../services/disclaimer.service';
import {StorageService} from '../../services/storage.service';


import {TrackingService} from '../../services/tracking.service';

@Component({
    selector: 'oap-edit-profil',
    templateUrl: './edit-profil.component.html',
    styleUrls: ['./edit-profil.component.scss'],
    providers: [...JJMMYYYY_FORMAT_PROVIDER]
})
export class EditProfilComponent implements OnInit {
    @ViewChild('fileDocAutre')
    editProfilForm: FormGroup;
    patientId: number;
    firstPatientId: number;
    medecinKey: number;
    adresseKey: number;
    patientModel: Observable<Patient>;
    parentPatientModel: Observable<Patient>;
    submitted = false;
    readOnlyPhysicianName = false;
    submitLoading = false;
    minDate = new Date(1900, 0, 1);
    maxDate: Date = new Date(formatDate(Date.now(), 'MM/dd/yyyy', 'en'));

    matcher = new OapStandarErrorStateMatcher();
    errors = errorMessages;
    validation_messages = OapErrorMessages;

    maxDocs: Set<{ typeDocumentKey: string, maxDoc: number }> = new Set();

    typesDocuments: TypeDocument[];

    docAssuranceMaladie: DialogData;
    showHelpAttAsm = false;
    docMutuelle: DialogData;
    showHelpAttMut = false;
    docAutre: DialogData;
    identifiantSAP:string;

    isParent: boolean;
    mobileParent: string;
    phoneParent: string;
    mailParent: string;

    public mask = [/[1-4,7-8]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /[0-9A-B]/, ' ',
        /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
    public maskTel = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    public disabled:boolean;
    public subscription;

    constructor(
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private adapter: DateAdapter<any>,
        private patientService: PatientService,
        private loaderService: LoaderService,
        private typeDocumentService: TypeDocumentService,
        private disclaimer: DisclaimerService,
        private tracking: TrackingService, private storageService: StorageService) {
            this.mobileParent = "";
            this.phoneParent = "";
            this.mailParent = "";
    }

    ngOnInit() {
        this.loaderService.showLoader();
        this.alertService.clear();
        this.messageService.sendMessage('page-home');

        this.adapter.setLocale('fr');
        this.infoPatient();
        this.subscription = this.storageService.watchStorage().subscribe((data:string) => {
            this.infoPatient();
        });

        /*
        this.patientService.getPatient(this.patientId).pipe(first()).subscribe(
            data => this.handleSuccessData(data),
            error => this.handleErrorPatient()
        );
        */

        //console.log(this.patientModel);
        this.tracking.DataLayer('/app/edit-profil','Rubrique','Mon profil','');
    }

    ngOnDestroy() {
        if(this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    openExternal(url: string) {
      this.disclaimer.openPopinExternal(url);
    }

    private loadDocument(patientKey: number) {
        this.typeDocumentService.getAll().pipe(first()).subscribe(tdo => {
            if (tdo) {
                //this.typesDocuments = tdo;
                const t = tdo.find(a => a.typeDocumentKey === 'ATT_ASS_MA');
                this.docAssuranceMaladie = new DialogDataImpl(patientKey, t);
                this.docMutuelle = new DialogDataImpl(patientKey, tdo.find(a => a.typeDocumentKey === 'ATT_MUT'));
                this.docAutre = new DialogDataImpl(patientKey, tdo.find(a => a.typeDocumentKey === 'ORD'));

                tdo.forEach(f => {
                    this.maxDocs.add({typeDocumentKey: f.typeDocumentKey, maxDoc: f.nbDocumentMax});
                });

                //console.log(this.maxDocs);
            }
        });
    }

    onFormSubmit(form: NgForm) {
        this.submitLoading = true;
        this.submitted = true;

        // stop here if form is invalid
        if (this.editProfilForm.invalid) {
            return;
        }

        const data = JSON.stringify(form.value);

        let numSS: string = form.controls.numeroSecuriteSociale.value;
        let mobile: string = form.controls.mobile.value;
        let telephoneFixe: string = form.controls.telephoneFixe.value;
        // permet de supprimer les espaces contenu dans le mask mask
        // a voir pour les validator si on peut les conserver
        // TODO ajout un validator pour vérifier la clé fourni
        numSS = numSS ? numSS.replace(/\s/g, '') : null;
        mobile = mobile ? mobile.replace(/\s/g, '') : null;
        telephoneFixe = telephoneFixe ? telephoneFixe.replace(/\s/g, '') : null;

        const dateNaiss: Moment = form.controls.dateNaissance.value;
        const dateNaissAssure: Moment = form.controls.dateNaissanceAssure.value;
        // console.log('iso : ' + dateNaiss.toISOString());
        // console.log('local' + dateNaiss.toLocaleString());
        const datePipe = new DatePipe('en');

        const patientUpdate: PatientUpdate = {
            patientKey: this.patientId,
            medecinKey: this.medecinKey,
            adresseKey: this.adresseKey,
            nom: form.controls.lastName.value,
            prenom: form.controls.firstName.value,
            dateNaissance: datePipe.transform(dateNaiss, 'yyyy-MM-dd'),
            email: form.controls.email.value,
            mobile: mobile,
            telephoneFixe: telephoneFixe,
            nomMedecin: form.controls.nomMedecin.value,
            numeroSecuriteSociale: numSS,
            nomCPAM: form.controls.nomCPAM.value,
            mutuelleCodePrefectoral: form.controls.mutuelleCodePrefectoral.value,
            mutuelleNumeroAdherent: form.controls.mutuelleNumeroAdherent.value,
            nomMutuelle: form.controls.nomMutuelle.value,
            mutuelleDateFinDroit: form.controls.mutuelleDateFinDroit.value,
            sansMutuelle: form.controls.sansMutuelle.value,
            accepteRecevoirEmail: form.controls.accepteRecevoirEmail.value,
            accepteNewsletters: form.controls.accepteNewsletters.value,
            adresse: {
                adresseKey: this.adresseKey,
                rue: form.controls.rue.value,
                complement: form.controls.complement.value,
                codePostale: form.controls.codePostale.value,
                ville: form.controls.ville.value
            },
            nomAssure: form.controls.nomAssure.value,
            prenomAssure: form.controls.prenomAssure.value,
            dateNaissanceAssure: datePipe.transform(dateNaissAssure, 'yyyy-MM-dd')/*,
            caisseSecuriteSociale: "",
            centreSecuriteSociale: "",
            codeExterne: 0,
            identifiantStallergenes: "",
            regimeSecuriteSociale: ""*/
        };
        //console.log("--- UPDATE ---");
        //console.log(patientUpdate);

        this.patientService.save(this.patientId, patientUpdate)
            .pipe(first())
            .subscribe(
                obsData => {
                    //this.router.navigate(['']);
                    this.alertService.success('Modifications enregistrées', true);
                    this.submitLoading = false;
                },
                error => {
                    this.alertService.error(error);
                    this.submitLoading = false;
                });

        this.tracking.pushDataLayer('Gestion profil','Validation','Modification du profil');
    }

    clickMutuelle(event){

        if ( event.checked ) {
            this.tracking.pushDataLayer('Gestion profil', 'Click', 'Pas de mutuelle');
        }
    }

    help(id) {
        if (id === 'ATT_ASM') {
            this.showHelpAttAsm = !this.showHelpAttAsm;
        } else {
            this.showHelpAttAsm = false;
        }

        if (id === 'ATT_MUT') {
            this.showHelpAttMut = !this.showHelpAttMut;
        } else {
            this.showHelpAttMut = false;
        }

        if (!id) {
            this.showHelpAttMut = false;
            this.showHelpAttAsm = false;
        }
    }

    onClick(e) {
        //console.log(' onClick ');
        this.showHelpAttAsm = false;
        this.showHelpAttMut = false;
    }

    private infoPatient(){
        this.patientId = parseInt(JSON.parse(localStorage.getItem('patientId')));
        this.firstPatientId = parseInt(JSON.parse(localStorage.getItem('firstPatientId')));
        this.isParent = (this.patientId == this.firstPatientId);
        this.disabled = !this.isParent;

        this.loadDocument(this.patientId);

        this.editProfilForm = this.formBuilder.group({
            nomMedecin: [''],
            lastName: ['', [Validators.required,
                Validators.maxLength(40),
                Validators.pattern(regExps.onlyLetters)]],
            firstName: ['', [Validators.required,
                Validators.maxLength(40),
                Validators.pattern(regExps.onlyLettersAccent)]],
            rue: ['', Validators.maxLength(50)],
            complement: ['', Validators.maxLength(50)],
            codePostale: ['', [Validators.maxLength(10),
                Validators.pattern(regExps.zipCode)]],
            ville: ['', Validators.maxLength(50)],
            mobile: [{value: '', disabled: this.disabled}, [CustomValidatorsModule.maskMinValidator(10),
                Validators.required]],
            telephoneFixe: [{value: '', disabled: this.disabled}, CustomValidatorsModule.maskMinValidator(10)],
            email: [{value: '', disabled: this.disabled}, [Validators.required,
                Validators.minLength(5),
                Validators.maxLength(256),
                Validators.email]],
            dateNaissance: [''],
            accepteRecevoirEmail: [''],
            accepteNewsletters: [''],
            numeroSecuriteSociale: ['', [CustomValidatorsModule.maskMinValidator(15),
                CustomValidatorsModule.checkNirKeyValidator()]],
            nomCPAM: ['', Validators.maxLength(100)],
            nomMutuelle: ['', Validators.maxLength(100)],
            mutuelleCodePrefectoral: ['', Validators.maxLength(20)],
            mutuelleNumeroAdherent: ['', Validators.maxLength(20)],
            mutuelleDateFinDroit: [''],
            sansMutuelle: [''],
            nomAssure: ['', [
                Validators.maxLength(40),
                Validators.pattern(regExps.onlyLetters)]],
            prenomAssure: ['', [
                Validators.maxLength(40),
                Validators.pattern(regExps.onlyLettersAccent)]],
            dateNaissanceAssure: ['']
        });

        if ( !this.isParent) {
            this.patientService.getPatient(this.firstPatientId).subscribe(
                data => {
                    this.mobileParent = data.mobile;
                    this.phoneParent = data.telephoneFixe;
                    this.mailParent = data.email;
                }
            );
        }


        this.patientModel = this.patientService.getPatient(this.patientId).pipe(
            tap(patient => {
                    //console.log("--- DISPLAY ---");
                    //console.log(patient);
                    this.editProfilForm.patchValue(
                        {
                            nomMedecin: patient.nomMedecin,
                            lastName: patient.nom,
                            firstName: patient.prenom,
                            mobile: patient.mobile,
                            telephoneFixe: patient.telephoneFixe,
                            email: patient.email,
                            dateNaissance: patient.dateNaissance,
                            rue: patient.adresse ? patient.adresse.rue : null,
                            complement: patient.adresse ? patient.adresse.complementRue : null,
                            codePostale: patient.adresse ? patient.adresse.codePostale : null,
                            ville: patient.adresse ? patient.adresse.ville : null,
                            accepteRecevoirEmail: patient.accepteRecevoirEmail,
                            accepteNewsletters: patient.accepteNewsletters,
                            numeroSecuriteSociale: patient.numeroSecuriteSociale,
                            nomCPAM: patient.nomCPAM,
                            nomMutuelle: patient.nomMutuelle,
                            mutuelleNumeroAdherent: patient.mutuelleNumeroAdherent,
                            mutuelleCodePrefectoral: patient.mutuelleCodePrefectoral,
                            mutuelleDateFinDroit: patient.mutuelleDateFinDroit,
                            sansMutuelle: patient.patientSansMutuelle,
                            nomAssure: patient.nomAssure,
                            prenomAssure: patient.prenomAssure,
                            dateNaissanceAssure: patient.dateNaissanceAssure
                        });
                    this.readOnlyPhysicianName = patient.medecinKey ? true : false;
                    this.medecinKey = patient.medecinKey;
                    this.adresseKey = patient.adresse ? patient.adresse.adresseKey : null;
                    this.identifiantSAP = patient.identifiantSAP;
                    this.loaderService.hideLoader();

                }
            )
        );
        //console.log(this.patientModel);
    }
}


