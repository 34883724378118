import {Component, OnInit, Inject, HostListener} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange} from '@angular/material';
import {TextesLegauxService} from '../textes-legaux.service';
import {tap, first} from 'rxjs/operators';
import {Article} from '../../../models/textes-legaux/article';
import {Router} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {TrackingService} from '../../../services/tracking.service';
import {a} from '@angular/core/src/render3';

export interface TextesLegauxDialogData {
    typeTexte: string;
}

@Component({
    selector: 'oap-view-textes-legaux',
    templateUrl: './view-textes-legaux.component.html',
    styleUrls: ['./view-textes-legaux.component.scss']
})


export class ViewTextesLegauxComponent implements OnInit {

    articles: Article[];
    title: string;
    compteKey: number;
    validationCGU: boolean = false;
    cguChecked: boolean = false;
    armChecked: boolean = false;

    constructor(public dialogRef: MatDialogRef<ViewTextesLegauxComponent>,
                private router: Router,
                private alertService: AlertService,
                private tracking: TrackingService,
                @Inject(MAT_DIALOG_DATA) public data: TextesLegauxDialogData,
                private textesLegauxService: TextesLegauxService,
                private authenticationService: AuthenticationService) {
    }

    @HostListener('click', ['$event'])
    onClick(event) {
        var target = event.target;
        if (target.tagName.toLowerCase() === 'a') {
            if (target.getAttribute('href').includes('ordoizzy@')) {
                this.tracking.pushDataLayer('Demande de contact', 'Click', 'Ordoizzy');
            }
            else if (target.getAttribute('href').includes('dpo.gdpr@')) {
                this.tracking.pushDataLayer('Demande de contact', 'Click', 'Dpo');
            }
            else if (target.getAttribute('href').includes('serviceclient@')) {
                this.tracking.pushDataLayer('Demande de contact', 'Click', 'serviceclient');
            }
            else if (target.getAttribute('href').includes('ordoizzy.stallergenesgreer.com')) {
                this.tracking.pushDataLayer('Lien sortant', 'Click', 'ordoizzy.stallergenesgreer.com');
            }
        }
    }

    ngOnInit() {
        const ls_cgu = JSON.parse(localStorage.getItem('validation-cgu'));
        console.log('ask validation ls_cgu : ' + ls_cgu);

        this.validationCGU = (ls_cgu === 'true' ? true : false);

        console.log('ask validation cgu : ' + this.validationCGU);

        const currentConnected = localStorage.getItem('currentUser');
        let controller: string;
        if (currentConnected) {
            const authToken = JSON.parse(currentConnected);
            this.compteKey = authToken.id;
            controller = 'media';
        } else {
            controller = 'ext';
        }

        // chargement du texte légal demandé
        if (this.data.typeTexte === 'cgu') {
            this.title = 'Conditions générales d\'utilisation';
            this.textesLegauxService.getCgu(controller)
                .pipe(first())
                .subscribe(
                    data => {
                        this.articles = data.articles;
                    },
                    error => {
                        console.error(error);
                    });

        } else if (this.data.typeTexte === 'ml') {
            this.title = 'Mentions légales';
            this.textesLegauxService.getMentionsLegales(controller)
                .pipe(first())
                .subscribe(
                    data => {
                        this.articles = data.articles;
                    },
                    error => {
                        console.error(error);
                    });
        }
    }

    closeDialog() {

        if (this.validationCGU) {

            this.alertService.error('La non acceptation des Conditions générales d\'utilisation ne vous permet pas d\'utiliser ce site', true);
            this.authenticationService.logout();
        }

        return this.dialogRef.close('false');
    }

    OnChangeCGU($event: MatCheckboxChange) {
        console.log($event);
        this.cguChecked = $event.checked;
    }

    OnChangeARM($event: MatCheckboxChange) {
        console.log($event);
        this.armChecked = $event.checked;
    }

    onSubmit() {
        console.log('onsubmit');

        this.textesLegauxService.validationCGU(this.compteKey, this.cguChecked, this.armChecked)
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    localStorage.setItem('validation-cgu', 'true');
                    return this.dialogRef.close('true');
                },
                error => {
                    console.log(error);
                });

        // return this.dialogRef.close();
    }

    downloadDoc(url: string) {
        var a = window.document.createElement('a');
        a.href = 'assets/pdf/' + url;
        //a.download = 'assets/pdf/'+url;
        a.target = '_blank';
        a.click();

        this.tracking.pushDataLayer('Contenus', 'Telechargement', 'CGU');
    }

}
