import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Patient } from '../models/patient';
import { PatientUpdate } from '../models/patientUpdate';
import { CommentaireSurPatient } from '../models/CommentaireSurPatient';

@Injectable()
export class PatientService {
  private apiUrl: string;

  constructor(private http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }

  getPatientsByCptKey(cptKey: string) {
    return this.http.get<Patient[]>(this.apiUrl + `/pat/cpt/` + cptKey)
        .pipe(map(pat => {
            return pat;
        }));
  }

  getPatient(patientId: number) {
    return this.http.get<Patient>(this.apiUrl + `/pat/` + patientId)
        .pipe(map(pat => {
            return pat;
        }));
  }

  getCommentaireSurPatient(patientId: number) {
    return this.http.get<CommentaireSurPatient>(this.apiUrl + `/pat/` + patientId + `/com`)
        .pipe(map(pat => {
            return pat;
        }));
  }

  updateCommentaireSurPatient(patientId: number, commentaireSurpatient: CommentaireSurPatient) {
    return this.http.put<CommentaireSurPatient>(this.apiUrl + `/pat/` + patientId + `/com`, commentaireSurpatient)
      .pipe(map(pat => {
        return pat;
      }));
  }
  
  save(patientId: number, patient: PatientUpdate) {
    return this.http.put<Patient>(this.apiUrl + `/pat/` + patientId.toString(), patient)
        .pipe(map(pat => {
            return pat;
        }));
  }

  updateDateFinMutuelle(patKey: number, dateFinMutuelle: string) {
    let val = {
      patientKey: patKey,
      mutuelleDateFinDroit: dateFinMutuelle
    }
    return this.http.put<Patient>(this.apiUrl + `/pat/` + patKey.toString()+ '/mut', val)
        .pipe(map(pat => {
            return pat;
        }));
  }

  getLivraisonsForPatient(patientId: number) {
    return this.http.get(this.apiUrl + `/pat/` + patientId + '/orders')
        .pipe(map(pat => {
            return pat
        }))
        
  }
  getCodeAuthentForPatient(patientId: number, typeEnvoi:string){
      return this.http.post(this.apiUrl + `/pat/` + patientId + '/otp/type/'+typeEnvoi, {})
          .pipe(map(pat => {
              return pat
          }))
    }

  validateCode(patientId: number, codeTemporaire:string){
      return this.http.post(this.apiUrl + `/pat/` + patientId + '/otp/'+codeTemporaire, {})
          .pipe(map(pat => {
              return pat
          }))
    }

}
