import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../../modules/change-password/dialog/dialog.component';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { UnregisterComponent } from '../../components/unregister/unregister.component';
import { AlertService } from '../../services/alert.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oap-header-bo',
  templateUrl: './header-bo.component.html',
  styleUrls: ['./header-bo.component.scss']
})
export class HeaderBoComponent implements OnInit {

  @Input() headerType: string;

  public winwdowTop: number;
  public headerOpen: boolean;
  public menusOpen: boolean;
  private patientId: number;
  private subscribeScroll: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private router: Router,
    private scrollDispatcher: ScrollDispatcher,
    private cf: ChangeDetectorRef) {
        this.winwdowTop = window.pageYOffset;
        this.headerOpen = false;
        this.menusOpen = false;
    }

  	ngOnInit() {
      if(!isNaN(parseInt(JSON.parse(localStorage.getItem('patientId'))))) {
          this.patientId = parseInt(JSON.parse(localStorage.getItem('patientId')));
      }
      else {
          this.patientId = -1;
      }
      this.subscribeScroll = this.scrollDispatcher.scrolled().subscribe(x => {
          this.winwdowTop = 50;
          this.cf.detectChanges();
      });
  }

  ngOnDestroy() {
      this.subscribeScroll.unsubscribe();
  }

  logout(e) {
      // reset login status
      this.authenticationService.logout();
      this.router.navigate(['/login']);
  }

  changePassword(e) {
      const dialogRef = this.dialog.open(DialogComponent,
          {
              disableClose: true,
              data: {expired: false}
          });
  }

  toggleHeader() {
      this.headerOpen = !this.headerOpen;
  }

  toggleMenus() {
      this.menusOpen = !this.menusOpen;
  }

  closeMenus() {
      this.menusOpen = false;
  }

}
