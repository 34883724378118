import {Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {first} from 'rxjs/operators';

import {PatientService} from '../../services/patient.service';
import {AuthToken} from '../../models/authToken';
import {Patient} from '../../models/patient';

import {MessageService} from '../../services/message.service';
import {AlertService} from '../../services/alert.service';
import {DialogComponent} from '../../modules/change-password/dialog/dialog.component';
import {MatDialog, MatSelectChange} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewTextesLegauxComponent} from '../../modules/textes-legaux/view-textes-legaux/view-textes-legaux.component';
import {FormGroup, FormBuilder} from '@angular/forms';
import {LoaderService} from '../../services/loader.service';
import {TrackingService} from '../../services/tracking.service';

import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { MenusComponent } from '../../components/menus/menus.component';

import { DisclaimerService } from '../../services/disclaimer.service';

@Component({
    selector: 'oap-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
    @ViewChild('fileDocAutre')
    eltRefFileDocAutre: ElementRef;
    authToken: AuthToken;
    patients: Patient[];
    currentPatient: Patient;
    patientId: number;

    mapPatient: Map<number, Patient>;

    public files: Set<File> = new Set();

    mesCommandesForm: FormGroup;

    retUpload;
    public scrollOffsetY: number;
    public offset: number;
    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private patientService: PatientService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private disclaimer: DisclaimerService,
        private tracking: TrackingService) {
        this.authToken = JSON.parse(localStorage.getItem('currentUser'));
        this.currentPatient = <Patient>({identifiantSAP: ''});
    }
    @HostListener('window:scroll', []) public scrolling(){
        let  the_video = document.getElementById("my_video");
        let  sticky_video = document.getElementById("sticky_video");

        this.scrollOffsetY = window.pageYOffset;
        this.offset = (the_video.offsetTop+ sticky_video.offsetHeight) - window.innerHeight;
        this.scrollOffsetY >= this.offset? sticky_video.className = 'sticky-right':sticky_video.className = 'sticky-right sticky-visible';
    }

    ngOnInit() {
        //this.loaderService.showLoader();
        this.messageService.sendMessage('page-home');
        this.mesCommandesForm = this.formBuilder.group({
            custumer_number: [''],
            surname: [''],
            firstname: [''],
            op: [''],
            form_id: ['']
        });

        this.tracking.DataLayer('/app','Homepage portail','','');
    }

    ngAfterViewInit() {
        const compteExpire = JSON.parse(localStorage.getItem('compte-expire'));
        const validationCGU = JSON.parse(localStorage.getItem('validation-cgu'));
        //console.log("debug popin");
        //console.log(compteExpire);
        //console.log("debug popin end");
        if (compteExpire === 'true') {
            const dialogRef = this.dialog.open(DialogComponent,
                {
                    disableClose: true,
                    data: {expired: true}
                });
            localStorage.setItem('compte-expire', 'false');
        }
        if (validationCGU === 'true') {
            const dialogRef = this.dialog.open(ViewTextesLegauxComponent,
                {
                    disableClose: true,
                    data: {typeTexte: 'cgu'}
                });
            dialogRef.afterClosed().subscribe(result => {
                //console.log('The dialog was closed');
                //console.log('result : ' + result);
                if (result) {
                    localStorage.setItem('validation-cgu', 'false');
                }
            });
        }
        setTimeout( ()=>{
            let  sticky_video = document.getElementById("sticky_video");
            sticky_video.className = 'sticky-right sticky-visible';
        }, 500)
    }

    changeSelectedPatient($event: MatSelectChange) {
        //console.log('selet -> changement de patient ');
        //console.log($event);
        this.currentPatient = this.mapPatient.get($event.value);
    }

    onSubmit(form: any, e: any): void {
        e.target.submit();
    }

    openExternal(url: string) {
      this.disclaimer.openPopinExternal(url);
    }
    scroleTo(id){
        document.getElementById(id).scrollIntoView();
    }
}
