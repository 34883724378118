import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatIconRegistry, DateAdapter } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Patient } from '../../../models/patient';
import { PatientService } from '../../../services/patient.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DatePipe} from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {JJMMYYYY_FORMAT_PROVIDER} from '../../../helpers/jjmmyyy-format.helper';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

export interface DialogData {
  patientKey: number;
}

@Component({
  selector: 'oap-detail-patient',
  templateUrl: './detail-patient.component.html',
  styleUrls: ['./detail-patient.component.scss'],
  providers: [...JJMMYYYY_FORMAT_PROVIDER]
})
export class DetailPatientComponent implements OnInit {
  detailPatientForm: FormGroup;
  patientModel: Observable<Patient>;
  submitted = false;
  detailPatientReadOnly = true;

  showSansMutuelle = false;
  accepteRecevoirEmail = true;

  datePipe = new DatePipe('fr');

  public mask = [/[1-4,7-8]/,' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /[0-9A-B]/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]
  public maskTel = [/\d/,/\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]

  constructor(public dialogRef: MatDialogRef<DetailPatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private iconRegistry: MatIconRegistry, 
    private sanitizer: DomSanitizer) { 
      iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-remove_red_eye-24px.svg'));
      iconRegistry.addSvgIcon('saveAs', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-save_alt-24px.svg'));
    }

  ngOnInit() {
    // console.log(" dialog -> patientKey : " + this.data.patientKey );

    this.detailPatientForm = this.formBuilder.group({
      nomMedecin : ['', ],
      lastName : ['', ],
      firstName : ['', ],
      rue : ['', ],
      complement : ['', ],
      codePostale : ['', ],
      ville : ['', ],
      mobile : ['', ],
      telephoneFixe : ['', ],
      email : ['', ],
      dateNaissance : ['', ],
      numeroSecuriteSociale : ['', ],
      nomCPAM : ['', ],
      nomMutuelle : ['', ],
      mutuelleCodePrefectoral : ['', ],
      mutuelleNumeroAdherent  : ['', ],
      mutuelleDateFinDroit    : ['', ],
      sansMutuelle            : ['', ],
      nomAssure               : ['', ],
      prenomAssure            : ['', ],
      dateNaissanceAssure     : ['', ],
    });

    const datePipe = new DatePipe('fr');

    this.patientModel = this.patientService.getPatient(this.data.patientKey).pipe(
      tap(patient => { this.detailPatientForm.patchValue(
        {
          nomMedecin: patient.nomMedecin,
          lastName: patient.nom,
          firstName: patient.prenom,
          mobile: patient.mobile,
          telephoneFixe: patient.telephoneFixe,
          email: patient.email,
          dateNaissance: datePipe.transform(patient.dateNaissance, 'dd/MM/yyyy'),
          rue: patient.adresse ? patient.adresse.rue : null,
          complement: patient.adresse ? patient.adresse.complementRue : null,
          codePostale: patient.adresse ? patient.adresse.codePostale : null,
          ville: patient.adresse ? patient.adresse.ville: null,
          numeroSecuriteSociale: patient.numeroSecuriteSociale,
          nomCPAM: patient.nomCPAM,
          nomMutuelle: patient.nomMutuelle,
          mutuelleNumeroAdherent: patient.mutuelleNumeroAdherent,
          mutuelleCodePrefectoral: patient.mutuelleCodePrefectoral,
          mutuelleDateFinDroit: datePipe.transform(patient.mutuelleDateFinDroit, 'dd/MM/yyyy'),
          sansMutuelle: patient.patientSansMutuelle,
          accepteRecevoirEmail: patient.accepteRecevoirEmail,
          nomAssure: patient.nomAssure,
          prenomAssure: patient.prenomAssure,
          dateNaissanceAssure: patient.dateNaissanceAssure
       });
      this.showSansMutuelle = patient.patientSansMutuelle;
      // console.log('showSansMutuelle :  ' + this.showSansMutuelle);
      this.accepteRecevoirEmail = patient.accepteRecevoirEmail;
      // console.log('accepteRecevoirEmail :  ' + this.accepteRecevoirEmail);
      }
    )
    );

    // console.log(this.patientModel);
  }

  closeDialog() {
    return this.dialogRef.close();
  }  

}
