import {Component, OnInit, Input, OnChanges, SimpleChange, ViewChild, ElementRef} from '@angular/core';
import {UploadService} from '../../upload/upload.service';
import {UploadedDocumentReduit} from '../../models/uploadedDocumentReduit';
import {first} from 'rxjs/operators';
import {AuthToken} from '../../models/authToken';
import {TypeDocumentService} from '../../services/type-document.service';
import {AlertService} from '../../services/alert.service';
import {OapHtmlDownloadFile} from '../../helpers/oap-html-download-file';
import {formatDate, DatePipe} from '@angular/common';
import {MatIconRegistry, MatDatepickerInputEvent, MatInput} from '../../../../node_modules/@angular/material';
import {DomSanitizer} from '../../../../node_modules/@angular/platform-browser';
import {PatientService} from '../../services/patient.service';
import {FormControl} from '../../../../node_modules/@angular/forms';
import {JJMMYYYY_FORMAT_PROVIDER} from '../../helpers/jjmmyyy-format.helper';
import {TrackingService} from '../../services/tracking.service';

@Component({
    selector: 'oap-uploded-document',
    templateUrl: './uploded-document.component.html',
    styleUrls: ['./uploded-document.component.scss'],
    providers: [...JJMMYYYY_FORMAT_PROVIDER]
})
export class UplodedDocumentComponent implements OnInit, OnChanges {
    @Input('pat_key') patKey: number;
    @Input('equ_doc') equDoc: string;
    @Input('select_date_expiration') showDateExpiration: boolean;

    authToken: AuthToken;
    @ViewChild('fileDoc')
    eltRefFile: ElementRef;
    uploadedDocuments: UploadedDocumentReduit[];
    documentsExist = false;
    maxDocumentReach = false;
    errorFilseSize = false;
    errorUpload = "";
    files: Set<File> = new Set();
    retUpload;

    @ViewChild('datValInputPicker') datValInputPicker: MatInput;
    datePipe = new DatePipe('en');
    minDate = new Date(formatDate(Date.now(), 'MM/dd/yyyy', 'en'));
    maxDate: Date = new Date(2019, 12, 31);

    fileSizeMax = '6 Mo';

    typeDocumentKey: string;
    labelDoc: string;

    maxDocs: Set<{ equivalence: string, maxDoc: number }> = new Set();

    dateMutuelle = new FormControl();

    constructor(private uploadService: UploadService,
                private typeDocumentService: TypeDocumentService,
                private patientService: PatientService,
                private alertService: AlertService,
                private oapHtmlDownloadFile: OapHtmlDownloadFile,
                private iconRegistry: MatIconRegistry,
                private sanitizer: DomSanitizer,
                private tracking: TrackingService) {
        iconRegistry.addSvgIcon('attach_file', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/baseline-attach_file-24px.svg'));
        iconRegistry.addSvgIcon('clear_date', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-delete_sweep-24px.svg'));
        iconRegistry.addSvgIcon('clear', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-clear-24px.svg'));
        this.authToken = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        if (this.patKey) {
            this.uploadService.getDocuments(this.patKey, this.equDoc).pipe(first()).subscribe(dto => {
                if (dto && dto.length) {
                    this.uploadedDocuments = dto;
                    //console.log(this.equDoc);
                    //console.log(dto);
                    this.documentsExist = true;
                }
            });
        }

        this.typeDocumentService.getByEquivalence(this.equDoc).pipe(first()).subscribe(tdo => {
            if (tdo) {

                tdo.forEach(f => {
                    this.maxDocs.add({equivalence: f.equivalence, maxDoc: f.nbDocumentMax});
                });

                if (tdo.length == 1) {
                    this.typeDocumentKey = tdo[0].typeDocumentKey;
                } else {
                    console.log('Plusieurs type de document pour equ : ' + this.equDoc);
                }
            }
        });
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (let propName in changes) {
            let changedProp = changes[propName];
            let to = JSON.stringify(changedProp.currentValue);
            if (!changedProp.isFirstChange()) {
                //   console.log(`Initial value of ${propName} set to ${to}`);
                let from = JSON.stringify(changedProp.previousValue);
                // console.log(`${propName} changed from ${from} to ${to}`);
                if (propName === 'patKey') {
                    this.upload(parseInt(to, 10));
                    if (this.showDateExpiration) {
                        // console.log('ngOnChanges  pat key : ' + this.patKey);
                        this.patientService.getPatient(this.patKey).pipe(first()).subscribe(pat => {
                            if (pat) {
                                //console.log("Date fin de droit : " + pat.mutuelleDateFinDroit);
                                this.dateMutuelle.patchValue(pat.mutuelleDateFinDroit);
                            }
                        });
                    }

                }
            }
            else { // specific to get the date on first change
                if (propName === 'patKey') {
                    if (this.showDateExpiration) {
                        this.patientService.getPatient(this.patKey).pipe(first()).subscribe(pat => {
                            if (pat) {
                                this.dateMutuelle.patchValue(pat.mutuelleDateFinDroit);
                            }
                        });
                    }
                }
            }
        }
    }

    clearDateExpiration() {
        //console.log('** clear date validité **');
        //this.dateMutuelle.patchValue(null);

        this.patientService.updateDateFinMutuelle(this.patKey, null).pipe(first())
            .subscribe(
                data => {
                    this.dateMutuelle.patchValue(data.mutuelleDateFinDroit);
                },
                error => {
                    console.log(error);
                }
            );

    }

    dateValiditeChange(event: MatDatepickerInputEvent<Date>) {
        let dv: Date = event.value;
        //console.log( 'date de validité : ' + this.datePipe.transform(dv, 'yyyy-MM-dd') ,)
        this.patientService.updateDateFinMutuelle(this.patKey, this.datePipe.transform(dv, 'yyyy-MM-dd')).pipe(first())
            .subscribe(
                data => {
                    //console.log('...update date...' + data);
                    this.dateMutuelle.patchValue(data.mutuelleDateFinDroit);
                },
                error => {
                    console.log(error);
                }
            );

    }


    private upload(patKey: number) {
        //console.log("on upload");
        this.uploadService.getDocuments(patKey, this.equDoc).pipe(first()).subscribe(dto => {
            this.documentsExist = false;
            if (dto) {
                this.uploadedDocuments = dto;
                if (dto.length > 0) {
                    this.documentsExist = true;
                }
            } else {
                this.uploadedDocuments = null;
            }
            this.isMaxDocumentReach();
        });
    }

    private isMaxDocumentReach() {
        this.maxDocumentReach = false;
        let max: number;

        this.maxDocs.forEach(c => {
            if (c.equivalence === this.equDoc) {
                max = c.maxDoc;
            }
        });

        if (this.uploadedDocuments && this.uploadedDocuments.length >= max) {
            this.maxDocumentReach = true;
            // console.log('max doc reach');
        }
    }


    onClickFile() {
        console.log('onClickFile : ' + this.equDoc);
        if (this.equDoc =='ATT_ASM') {
            this.labelDoc = 'Attestation de securite sociale';
        }
        else if (this.equDoc == 'ATT_MUT') {
            this.labelDoc = 'Mutuelle';
        }
        else if (this.equDoc == 'ORD') {
            this.labelDoc = 'Ordonnance';
        }

        this.alertService.clear();

        this.errorFilseSize = false;
        this.files = new Set();
        this.eltRefFile.nativeElement.click();
    }

    onFilesAdded() {
        //console.log('onFilesAdded : ' + this.equDoc);
        this.alertService.clear();

        const files: { [key: string]: File } = this.eltRefFile.nativeElement.files;
        for (let key in files) {
            if (!isNaN(parseInt(key))) {
                this.files.add(files[key]);
            }
        }

        this.retUpload = this.uploadService.upload(this.files, this.typeDocumentKey, this.authToken.id, this.patKey);

        // convert the progress map into an array
        let allProgressObservables = [];
        for (let key in this.retUpload) {

            this.retUpload[key].progress.subscribe(
                end => console.log(key + '...uploding...'),
                err => {
                    this.errorFilseSize = true;
                    this.errorUpload = err;
                    //console.log(key + ' err : ' + err);
                },
                () => {
                    //console.log(key + ' complete');
                    this.tracking.pushDataLayer('Gestion profil','Document depose',this.labelDoc);
                    this.alertService.success('Fichier correctement téléchargé.', true);
                    this.upload(this.patKey);
                }
            );
        }
        //console.log(allProgressObservables);

    }

    deleteDoc(uploadedDocumentKey) {
        //console.log('onDeleteDoc : ' + uploadedDocumentKey);
        this.alertService.clear();
        this.errorFilseSize = false;
        this.uploadService.delete(uploadedDocumentKey).pipe(first())
            .subscribe(
                data => {
                    //console.log('...deleting...' + data['success']);
                    this.alertService.success('Fichier correctement supprimé.', true);
                    this.upload(this.patKey);
                },
                error => {
                    console.log(error);
                }
            );
    }

    downloadFile(uploadedDocumentKey, fileName, mimeType) {
        //console.log('downloadFile : ' + uploadedDocumentKey + ' - mime type : ' + mimeType);
        this.uploadService.download(uploadedDocumentKey, mimeType).pipe(first())
            .subscribe(
                data => {

                    var blob = new Blob([data], {type: mimeType});

                    this.oapHtmlDownloadFile.downloadFileOnNavigator(blob, fileName, false);

                },
                error => console.log('Error downloading the file : ' + error),
                () => console.log('Completed file download.')
            );
    }
}
