import { Component, OnInit } from '@angular/core';
import {PatientService} from '../../services/patient.service';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {
    errorMessages,
    OapErrorMessages,
    OapStandarErrorStateMatcher,
    regExps
} from '../../modules/custom-validators/custom-validators.module';
import {ErrorStateMatcher} from '@angular/material';
import {Router} from '@angular/router';

import {TrackingService} from '../../services/tracking.service';
import {LoaderService} from '../../services/loader.service';

export class RegisterErrorStateMatcherComponent implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
@Component({
  selector: 'oap-double-authent',
  templateUrl: './double-authent.component.html',
  styleUrls: ['./double-authent.component.scss']
})
export class DoubleAuthentComponent implements OnInit {
    public phoneNumber: string;
    public smsNumber: string;
    //private patientId =JSON.parse(localStorage.getItem('patientId'));
    private patientId: number;
    public getcodeerror ;
    public getcode = {} ;
    public codevalide = true;
    codeForm: FormGroup;
    loading = false;
    submitted = false;

    errors = errorMessages;

    code_validation_messages = OapErrorMessages;
    matcher = new OapStandarErrorStateMatcher();
  constructor(
      private patientService: PatientService,
      private formBuilder: FormBuilder,
      private tracking: TrackingService,
      private loaderService: LoaderService,
      private router: Router) {
        let patients = JSON.parse(localStorage.getItem('patients')); // on affiche toujours le numéro de téléphone du parent
        this.patientId = parseInt(patients[0].patientKey);
      }

  ngOnInit() {
      this.loaderService.showLoader();
      this.patientService.getPatient(this.patientId).subscribe(
          data=>{
              if (data.mobile == null){
                  this.phoneNumber=""
              }else {
                  this.phoneNumber = data.mobile;
                  this.smsNumber =data.mobile.substring(6) ;
              }
              this.loaderService.hideLoader();
          });
      this.codeForm = this.formBuilder.group({
          code: ['', Validators.required],
      });
      this.tracking.DataLayer('/app/double-authent','Rubrique','Ma livraison','');
  }
    getCodeAuthent(){
        this.patientService.getCodeAuthentForPatient(this.patientId,'SMS').subscribe(
            data=>{
                this.getcode = data;
                this.getcodeerror=this.phoneNumber;
            },
            error=>{
                this.getcodeerror = '';
            });

    }
    onSubmit(form: FormGroup){
        if (this.codeForm.invalid) {
            return;
        }
        this.patientService.validateCode(this.patientId,form.controls.code.value).subscribe(
            data=>{
                this.codevalide = data['valid'];
                this.tracking.pushDataLayer('Livraison', 'SMS', this.codevalide ? 'Valide' : 'Invalide');
                if (this.codevalide) {
                    localStorage.setItem('doubleauthent', true.toString());
                    this.router.navigate(['/delivery']);
                }
            },
            error => {
                console.log(error) 
            });
    }

}
