import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { map } from 'rxjs/operators';
import { Gestionnaire } from '../../models/gestionnaire';

export interface SearchElement {
  nom: string;
  prenom: string;
  numeroSS: string;
  telPatient: string;
  codeMedecin: string;
  nomMedecin: string;
  numeroOrdonnance: number;
  dateOrdonnance: string;
  dateDepotDu: string;
  dateDepotAu: string;
  avecPatientSansDocument: Boolean;
  avecDocumentTraite: Boolean;
	numColTri:number; // numéro de la colonne à trié
	limit:number; // nombre de ligne dans une page
	pageOffset:number; // décalage de page
	directionTri:string; // asc ou desc
}

export interface ProcessDocument {
  documentKey: number;
  gestionnaireKey: number;
	process: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class GestionnaireService {
  private apiUrl: string;

  constructor(private _http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }

  search(searchInfo: SearchElement) {
    return this._http.post<any>(this.apiUrl + `/bo/search`, searchInfo)
        .pipe(map(c => {
            // login successful if there's a jwt token in the response
            //if (tokens && tokens.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
            //    localStorage.setItem('currentUser', JSON.stringify(tokens));
            //}
            return c;
        }));
  }

  process(processDocument : ProcessDocument) {
    return this._http.post<any>(this.apiUrl + `/bo/process`, processDocument)
        .pipe(map(c => {
            return c;
        }));
  }

  getGestionnaireByCptKey(compteKey: string) {
    return this._http.get<Gestionnaire[]>(this.apiUrl + `/bo/ges/cpt/` + compteKey)
        .pipe(map(ges => {
            return ges;
        }));
  }

}
