import {Component, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {PatientService} from '../../services/patient.service';
import {AuthToken} from '../../models/authToken';
import {Patient} from '../../models/patient';
import {ActivatedRoute} from '@angular/router';

import { DisclaimerService } from '../../services/disclaimer.service';

@Component({
    selector: 'oap-menus',
    templateUrl: './menus.component.html',
    styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {

    public patientId: number;
    authToken: AuthToken;
    mapPatient: Map<number, Patient>;
    patients: Patient[];
    currentPatient: Patient;

    constructor(
        private patientService: PatientService,
        private route: ActivatedRoute,
        private disclaimer: DisclaimerService) {
            
    }

    ngOnInit() {
        // this.authToken = JSON.parse(localStorage.getItem('currentUser'));
        // this.currentPatient = <Patient>({identifiantSAP: ''});
        // let patKey = parseInt(JSON.parse(localStorage.getItem('patientId')));
        // this.patientService.getPatientsByCptKey(this.authToken.id).pipe(first()).subscribe(pat => {
        //     if (pat) {
        //         this.mapPatient = new Map<number, Patient>();
        //         pat.forEach(p => {
        //             this.mapPatient.set(p.patientKey, p);
        //         });
        //         this.patients = pat;
        //         if (patKey && !isNaN(patKey)) {
        //             this.currentPatient = this.mapPatient.get(patKey);
        //         } else {
        //             this.currentPatient = this.patients[0];
        //         }
        //         this.patientId = this.currentPatient.patientKey;
        //         localStorage.setItem('patientIdEdit', this.patientId.toString());
        //     }
        // });
        // if (!isNaN(parseInt(JSON.parse(localStorage.getItem('patientId'))))) {
        //     this.patientId = parseInt(JSON.parse(localStorage.getItem('patientId')));
        //     localStorage.setItem('patientIdEdit', this.patientId.toString());
        // }
    }

    openExternal(url: string) {
      this.disclaimer.openPopinExternal(url);
    }

}
