import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessageService} from './services/message.service';
import {Subscription} from 'rxjs';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {FormGroup, FormBuilder} from '@angular/forms';
import {LoaderService} from './services/loader.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'oap-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('loaderContainer') loaderContainer: ElementRef;

    title = 'OrdoAPSI Patient';
    message: any;
    subscription: Subscription;

    submitted = false;

    constructor(
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private loaderService: LoaderService,
        private scrollDispatcher: ScrollDispatcher,
        private router: Router) {
            iconRegistry.addSvgIcon('power_off', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-power_settings_new-24px.svg'));
            iconRegistry.addSvgIcon('reorder', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-reorder-24px.svg'));
            iconRegistry.addSvgIcon('lock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-lock-24px.svg'));
            iconRegistry.addSvgIcon('out', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-reply-24px.svg'));
            iconRegistry.addSvgIcon('order', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-receipt-24px.svg'));
    }

    ngOnInit() {
        this.loaderService.initialize(this.loaderContainer.nativeElement);
        // subscribe to home component messages
        this.subscription = this.messageService.getMessage().subscribe(async (message) => {
            //console.log(' app receive : ' + message.text);
            this.message = await message;

        });
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // if ("ga" in window) {
                //     let tracker = (<any>window).ga.getAll()[0];
                //     if (tracker) {
                //         tracker.send('pageview', evt.urlAfterRedirects);
                //     }
                // }
            }
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    mesCommandes(e) {
        //console.log('*** mesCommandes ***');
        e.target.submit;
    }

    onSubmit(form: any, e: any): void {
        e.target.submit();
    }

}
