import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource, MatSortable, MatSort, Sort, MatIconRegistry } from '@angular/material';
import { AdminService } from '../admin.service';
import { first } from 'rxjs/operators';
import { regExps, OapStandarErrorStateMatcher, errorMessages, OapErrorMessages, CustomValidatorsModule } from '../../custom-validators/custom-validators.module';
import { DomSanitizer } from '@angular/platform-browser';
import { GestionnaireUpdate } from '../../../models/gestionnaireUpdate';
import { AlertService } from '../../../services/alert.service';

import { HeaderBoComponent } from '../../../components/header-bo/header-bo.component';

export interface AdminResultData {
  rowId: number;
  gestionnaire_key: number;
  compte_key: number;
  gestionnaire: string;
  nom: string;
  prenom: string;
  email: string;
  telephone: string;
  actif: boolean;
  supprimer: boolean;
  identifiant: string;
  role: string;
}

export interface EnableGestionnaireData {
  compteKey: number;
  gestionnaireKey: number;
  activer: boolean;
  supprimer: boolean;
}

export interface ResetPasswordGestionnaireData {
  compteKey: number;
  gestionnaireKey: number;
  identifiant: string;
  email: string;
}

export interface TypeCompte {
  code: string;
  libelle: string;
}


@Component({
  selector: 'oap-adm-home',
  templateUrl: './adm-home.component.html',
  styleUrls: ['./adm-home.component.scss']
})
export class AdmHomeComponent implements OnInit {
  adminForm: FormGroup;
  submitted = false;

  typesCompte: TypeCompte[] = [ {code:'T', libelle:'Téléconseiller' }, {code:'G', libelle:'Gestionnaire' } , {code:'A', libelle:'Administrateur' }];

  displayedColumns: string[] = ['rowId', 'identifiant', 'role', 'gestionnaire', 'email', 'telephone', 'actif','actions',];
  dataSource: MatTableDataSource<AdminResultData>;
  @ViewChild(MatSort) sort: MatSort;

  matcher = new OapStandarErrorStateMatcher();
  errors = errorMessages;
  validation_messages = OapErrorMessages;

  public maskTel = [/\d/,/\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]

  mapAdmin: Map<number, AdminResultData>;
  currentAdmin: AdminResultData;

  selectedRowIndex: number = -1;

  editMode = false;
  addMode = false;

  title: string = 'Consultation';

  tooltip_reset_password="Reset du mot de passe";

  constructor(private messageService: MessageService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private iconRegistry: MatIconRegistry, 
    private sanitizer: DomSanitizer) { 
      iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-edit-24px.svg'));
      iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-add-24px.svg'));
      iconRegistry.addSvgIcon('reset', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-cached-24px.svg'));
    }

  ngOnInit() {
    this.messageService.sendMessage('page-admin');

    this.adminForm = this.formBuilder.group({
      identifiant : ['', [Validators.required, Validators.maxLength(40)]],
      typeCompte: [{value: '', disabled:true}, ],
      lastName : ['', [Validators.required, Validators.maxLength(100)]],
      firstName : ['', [Validators.maxLength(100),
        Validators.pattern(regExps.onlyLettersAccent)]],
      phone : ['', CustomValidatorsModule.maskMinValidator(10)],
      email: ['', [Validators.required, 
        Validators.minLength(5), 
        Validators.maxLength(256),
        Validators.email]],
      actif: [{value: '', disabled:true}, ]
    });

    this.loadListGestionnaire();
  }

  private loadListGestionnaire() {
    this.adminService.findAll()
    .pipe(first())
    .subscribe(
        data => {
          //console.log(data);
          const results: AdminResultData[] = [];
          this.mapAdmin = new Map<number, AdminResultData>();
          let i: number = 1;
          let current: AdminResultData;
          data.forEach(element => {
            let entity: AdminResultData = createAdminResultData(i, element);
            results.push(entity);
            this.mapAdmin.set(entity.gestionnaire_key, entity);
            if (i == this.selectedRowIndex) {
              current = entity;
            }
            i++;
          });
          this.dataSource = new MatTableDataSource(results);

          this.sort.sort(<MatSortable>{
            id: 'gestionnaire',
            start: 'asc'
          });

          if (this.selectedRowIndex > 0) {
            this.onRowClick(current);
          }

        },
        error => {
          console.error(error)
        });
  }

  private setForm(entity: AdminResultData) {
    this.adminForm.patchValue(
      {
        identifiant : entity.identifiant,
        typeCompte: entity.role,
        lastName : entity.nom,
        firstName : entity.prenom,
        phone : entity.telephone,
        email : entity.email,
        actif: entity.actif
     });    
  }


  sortData(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }

    this.dataSource = new MatTableDataSource( data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'patient': return compare(a.gestionnaire, b.gestionnaire, isAsc);
        default: return 0;
      }
    }));
  }



  onFormSubmit(form: FormGroup) {

    this.submitted = true;

    // stop here if form is invalid
    if (this.adminForm.invalid) {
      return;
    }

    let phone: string = form.controls.phone.value;
    phone = phone ? phone.replace(/\s/g, "") : null;

    const gestionnaireUpdate: GestionnaireUpdate = {
      gestionnaireKey: this.currentAdmin.gestionnaire_key,
      compteKey: this.currentAdmin.compte_key,
      identifiant: form.controls.identifiant.value,
      role: form.controls.typeCompte.value,
      nom: form.controls.lastName.value,
      prenom: form.controls.firstName.value,
      email: form.controls.email.value,
      telephone: phone,
      actif: form.controls.actif.value,
    };

    console.log(gestionnaireUpdate);

    if(this.addMode) {
      this.adminService.add(gestionnaireUpdate)
      .pipe(first())
      .subscribe(
          data => {
            console.log('Add Ok');
            this.alertService.success('Modifications enregistrées', true);
            this.addMode = false;
            this.editMode = false;
            this.adminForm.reset();
            // reload table
            this.loadListGestionnaire();
            },
          error => {
            console.log(error);
            this.alertService.error(error);
          });
    } else {
      this.adminService.save(this.currentAdmin.compte_key, this.currentAdmin.gestionnaire_key, gestionnaireUpdate)
      .pipe(first())
      .subscribe(
          data => {
            console.log('save Ok');
            this.alertService.success('Modifications enregistrées', true);
            this.addMode = false;
            this.editMode = false;
            this.adminForm.reset();
            // reload table
            this.loadListGestionnaire();
          },
          error => {
            console.log(error);
            this.alertService.error(error);
          });
    }
  }

  onRowClick(row) {
    this.alertService.clear();
    console.log('**** row click');
    console.log(row);
    console.log('****************');
    this.title = 'Consultation';
    this.addMode = false;
    this.editMode = false;
    this.doDisableFormInput('typeCompte', !this.editMode);
    this.doDisableFormInput('actif', !this.editMode);
    this.selectedRowIndex = row.rowId;
    this.currentAdmin = this.mapAdmin.get(row.gestionnaire_key);
    this.setForm(this.currentAdmin);
  }

  toggleActif(gestionnaire: AdminResultData) {
    console.log(' toggleActif :' + gestionnaire.gestionnaire_key);
    
    gestionnaire.actif = !gestionnaire.actif;

    const enableGestionnaire: EnableGestionnaireData = {
      compteKey: gestionnaire.compte_key,
      gestionnaireKey: gestionnaire.gestionnaire_key,
      activer: gestionnaire.actif,
      supprimer: !gestionnaire.actif
    };

    console.log(enableGestionnaire);

    this.adminService.enable(enableGestionnaire)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let entity: AdminResultData = createAdminResultData(gestionnaire.rowId, data);
          this.mapAdmin.set(entity.gestionnaire_key, entity);
          gestionnaire.actif = entity.actif;
          gestionnaire.supprimer = entity.supprimer;
        },
        error => {
          console.error(error)
        });

  }

  toggleSupprimer(gestionnaire: AdminResultData) {
    console.log(' toggleDesactive document :' + gestionnaire.gestionnaire_key);
    
    gestionnaire.supprimer = !gestionnaire.supprimer;

    const enableGestionnaire: EnableGestionnaireData = {
      compteKey: gestionnaire.compte_key,
      gestionnaireKey: gestionnaire.gestionnaire_key,
      activer: gestionnaire.actif,
      supprimer: !gestionnaire.actif
    };

    console.log(enableGestionnaire);

    this.adminService.enable(enableGestionnaire)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let entity: AdminResultData = createAdminResultData(gestionnaire.rowId, data);
          this.mapAdmin.set(entity.gestionnaire_key, entity);
          gestionnaire.actif = entity.actif;
          gestionnaire.supprimer = entity.supprimer;
        },
        error => {
          console.error(error)
        });

  }

  onEdit() {
    this.alertService.clear();
    if(this.selectedRowIndex > -1) {
      console.log(' onEdit ');
      this.title = 'Edition';
      this.editMode = !this.editMode;
      this.addMode = false;
      this.doDisableFormInput('typeCompte', !this.editMode);
      this.doDisableFormInput('actif', !this.editMode);
    } else {
      console.log(' No data to edit ');
    }
  }

  onAdd() {
    this.alertService.clear();
    console.log(' onAdd ');
    this.title = 'Ajout';
    this.selectedRowIndex = -1;
    this.addMode = true;
    this.editMode = true;
    this.doDisableFormInput('typeCompte', false);
    this.doDisableFormInput('actif', false);
    this.myClearForm();
    if(this.adminForm.errors) {this.adminForm.errors.reset();}
    //this.adminForm.reset();
  }

  onResetPassword(gestionnaire: AdminResultData) {
    console.log(' reset password  :' + gestionnaire.compte_key);
    
    gestionnaire.actif = !gestionnaire.actif;

    const resetPasswordGestionnaire: ResetPasswordGestionnaireData = {
      compteKey: gestionnaire.compte_key,
      gestionnaireKey: gestionnaire.gestionnaire_key,
      identifiant: gestionnaire.identifiant,
      email: gestionnaire.email
    };

    console.log(resetPasswordGestionnaire);

    this.adminService.resetPassword(resetPasswordGestionnaire)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let entity: AdminResultData = createAdminResultData(gestionnaire.rowId, data);
          this.mapAdmin.set(entity.gestionnaire_key, entity);
          gestionnaire.actif = entity.actif;
          gestionnaire.supprimer = entity.supprimer;
        },
        error => {
          console.error(error)
        });
  }


  private doDisableFormInput(compnentName: string, disabled: boolean) {
    let control = this.adminForm.get(compnentName)
    if(disabled) {
      control.disable();
    } else {
      control.enable();
    }
    //control.disabled ? control.enable() : control.disable()    
  }

  private myClearForm() {
    this.currentAdmin =   {
      rowId: null,
      gestionnaire_key: null,
      compte_key: null,
      gestionnaire : null,
      nom : null,
      prenom : null,
      email: null,
      telephone: null,
      actif: true,
      supprimer: false,
      identifiant : null,
      role: 'G'
    }
    this.setForm(this.currentAdmin);
    
  };

}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

/** Builds and returns a new SearchResultData. */
function createAdminResultData(rowId:number, data: any): AdminResultData {
  return {
    rowId: rowId,
    gestionnaire_key: data.gestionnaireKey,
    compte_key: data.compteKey,
    gestionnaire : data.nom  + (data.prenom ? ' ' + data.prenom : ''),
    nom : data.nom,
    prenom : data.prenom ? data.prenom : '',
    email: data.email,
    telephone: data.telephone,
    actif: data.compte.actif,
    supprimer: data.supprimer,
    identifiant : data.compte.login,
    role: data.compte.role
  };
}  