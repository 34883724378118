import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable()
export class AppConfigService {
    private appConfig;
    location: Location;

    constructor (private injector: Injector, location: Location) { this.location = location; }

    loadAppConfig() {
        const _http = this.injector.get(HttpClient);

        const url_config = this.location.prepareExternalUrl('/assets/oap-config.json');

        //console.log('access oap-config.json : ' +url_config);

        return _http.get(url_config)
        .toPromise()
        .then(data => {
            this.appConfig = data;
        })
        .catch(error => {
            console.error('Error loading app-config.json, using envrionment file instead');
        });
    }

    get config() {
        return this.appConfig;
    }
}
