import { Component, OnInit, Input } from '@angular/core';
import { DialogComponent } from '../../modules/change-password/dialog/dialog.component';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { ViewTextesLegauxComponent } from '../../modules/textes-legaux/view-textes-legaux/view-textes-legaux.component';
import { DisclaimerService } from '../../services/disclaimer.service';
import {TrackingService} from '../../services/tracking.service';

@Component({
  selector: 'oap-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() footerType: string;

  urlPolConf = 'http://stallergenesgreer.fr/politique-de-confidentialité';

  constructor(
    private dialog: MatDialog,
    private disclaimer: DisclaimerService,
    public tracking: TrackingService
    ) {
  	
  }

  ngOnInit() {

  }

  loadCgu() {
        //console.log('*** load CGU ***');
        const dialogRef = this.dialog.open(ViewTextesLegauxComponent,
            {
                panelClass: 'oap-dialog',
                disableClose: false,
                data: {
                    typeTexte: 'cgu'
                }
            }
        );
      this.tracking.pushDataLayer('Contenus','Vues','CGU');
  }

    loadMentionsLegales() {
        //console.log('*** load Mentions légales ***');
        const dialogRef = this.dialog.open(ViewTextesLegauxComponent,
            {
                disableClose: false,
                data: {
                    typeTexte: 'ml'
                }
            }
        );
        this.tracking.pushDataLayer('Contenus','Vues','Mentions legales');
    }

    openExternal(url: string) {
      this.disclaimer.openPopinExternal(url);
    }
}
