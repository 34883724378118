import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {

	private subject = new Subject<any>();

  constructor() {

  }

  openPopinExternal(url: string) {
  	this.subject.next({ type: 'success', text: url });
  }

  openExternal(url: string) {
  	window.open(url);
  }

  clear() {
      this.subject.next();
  }

  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }
}
