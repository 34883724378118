import { AfterViewInit, Directive, ElementRef, EventEmitter, forwardRef, Inject, Injectable, InjectionToken, Injector, Input, NgZone, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../services/app-config.service';
import {TrackingService} from '../services/tracking.service';

declare const grecaptcha : any;

declare global {
  interface Window {
    grecaptcha : any;
    reCaptchaLoad : () => void
  }
}

export interface RecaptchaResponse {
  success: boolean;
  challenge_ts: Date;  // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
  hostname: string;         // the hostname of the site where the reCAPTCHA was solved
  "error-codes": string[];  
}

export const RECAPTCHA_URL = new InjectionToken('RECAPTCHA_URL');

@Injectable()
export class ReCaptchaAsyncValidator {

  url_verif_captcha: string;

  constructor( private http : HttpClient, 
    @Inject(RECAPTCHA_URL) private url,
    private _appConfig: AppConfigService, ) {

      this.url_verif_captcha = _appConfig.config.url_oap_reCaptcha;
      //console.log('config url captcha : ' + this.url_verif_captcha);
  }

  validateToken( token : string ) {
    return ( _ : AbstractControl ) => {
      return this.http.get<RecaptchaResponse>(this.url_verif_captcha, { params: { token } })
      .pipe(
        map(res =>  {
          if( !res.success ) {
            return { tokenInvalid: true }
          }
          return null;
        })
      );
    }
  }
}

export interface ReCaptchaConfig {
  theme? : 'dark' | 'light';
  type? : 'audio' | 'image';
  size? : 'compact' | 'normal';
  tabindex? : number;
}

@Directive({
  selector: '[nbRecaptcha]',
  exportAs: 'nbRecaptcha',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReCaptchaDirective),
      multi: true
    },
    ReCaptchaAsyncValidator
  ]
})
export class ReCaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() key : string;
  @Input() config : ReCaptchaConfig = {};
  @Input() lang : string;

  @Output() captchaResponse = new EventEmitter<string>();
  @Output() captchaExpired = new EventEmitter();

  private control : FormControl;
  private widgetId : number;

  private onChange : ( value : string ) => void;
  private onTouched : ( value : string ) => void;
  private category: string;
  constructor( private element : ElementRef, private  ngZone : NgZone, private injector : Injector, private reCaptchaAsyncValidator : ReCaptchaAsyncValidator ,private tracking: TrackingService ) {
  }

  ngOnInit() {
    this.registerReCaptchaCallback();
    this.addScript();
    if(location.href.includes('/register')){
        this.category ='Creation de compte';
    }
    else if(location.href.includes('/forget-password')){
        this.category ='Mot de passe oublié';
    }
  }

  registerReCaptchaCallback() {
    window.reCaptchaLoad = () => {
      const config = {
        ...this.config,
        'sitekey': this.key,
        'callback': this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this)
      };
      this.widgetId = this.render(this.element.nativeElement, config);
    };
  }

  ngAfterViewInit() {
    this.control = this.injector.get(NgControl).control;
    this.setValidators();
  }

  /**
   * Useful for multiple captcha
   * @returns {number}
   */
  getId() {
    return this.widgetId;
  }

  /**
   * Calling the setValidators doesn't trigger any update or value change event.
   * Therefore, we need to call updateValueAndValidity to trigger the update
   */
  private setValidators() {
    this.control.setValidators(Validators.required);
    this.control.updateValueAndValidity();
  }

  writeValue( obj : any ) : void {
  }

  registerOnChange( fn : any ) : void {
    this.onChange = fn;
  }

  registerOnTouched( fn : any ) : void {
    this.onTouched = fn;
  }

  /**
   * onExpired
   */
  onExpired() {
    this.ngZone.run(() => {
      this.captchaExpired.emit();
      this.onChange(null);
      this.onTouched(null);
      this.tracking.pushDataLayer(this.category ,'Click','Captcha expire');
    });
  }

  /**
   *
   * @param response
   */
  onSuccess( token : string ) {
    this.ngZone.run(() => {
      this.verifyToken(token);
      this.captchaResponse.next(token);
      this.onChange(token);
      this.onTouched(token);
      this.tracking.pushDataLayer(this.category ,'Click','Captcha valide');
    });
  }

  /**
   *
   * @param token
   */
  verifyToken( token : string ) {
    this.control.setAsyncValidators(this.reCaptchaAsyncValidator.validateToken(token))
    this.control.updateValueAndValidity();
  }

  /**
   * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
   * @param element
   * @param config
   * @returns {number}
   */
  private render( element : HTMLElement, config ) : number {
    return grecaptcha.render(element, config);
  }

  /**
   * Resets the reCAPTCHA widget.
   */
  reset() : void {
    if( !this.widgetId ) return;
    grecaptcha.reset(this.widgetId);
    this.onChange(null);
  }

  /**
   * Gets the response for the reCAPTCHA widget.
   * @returns {string}
   */
  getResponse() : string {
    if( !this.widgetId )
      return grecaptcha.getResponse(this.widgetId);
  }

  /**
   * Add the script
   */
  addScript() {
    let script = document.createElement('script');
    const lang = this.lang ? '&hl=' + this.lang : '';
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

}