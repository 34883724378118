import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oap-magazine',
  templateUrl: './magazine.component.html',
  styleUrls: ['./magazine.component.scss']
})
export class MagazineComponent implements OnInit {
  public articles;

  constructor() { }

  ngOnInit() {
    this.setArticles();
  }

  setArticles() {
    this.articles = [
      {
        slug: 'alertes-aux-pollen-ayez-les-bons-reflexes',
        category: 'Newsetter Juin 2020',
        title: 'Alertes aux pollens : ayez les bons réflexes !',
        image: './assets/images/magazines/alertes-aux-pollen-ayez-les-bons-reflexes.jpg',
        description: 'En raison de la maladie COVID-19, le port du masque s’est démocratisé. Or, ce dispositif sanitaire est un puissant allié dans la lutte contre les allergies. Dans un communiqué récent, la Fédération française d’allergologie (FFAL) conseille de porter systématiquement un masque pour diminuer le risque d’allergie respiratoire. Pensez à le mettre, notamment quand vous faites des activités extérieures et êtes en contact avec des pollens allergisants. ',
      },
      {
        slug: 'les-allergies-ne-prennent-pas-de-vacances',
        category: 'Newsetter Décembre 2020',
        title: 'Les allergies ne prennent pas de vacances !',
        image: './assets/images/magazines/les-allergies-ne-prennent-pas-de-vacances.jpg',
        description: 'Vous avez un chat ou un chien à la maison ? Vous avez probablement constaté que sa fourrure laisse des traces sur le sol, le tapis et le canapé tout au long de l’année. Et au grand désarroi des personnes allergiques, ces chutes de poils sont accentuées en automne et en hiver, lors des périodes de mue. ',
      },
      {
        slug: 'stallergenes-greer-vous-souhaite-une-annee-2021-sous-le-signe-de-la-sante',
        category: 'Newsetter Janvier 2021',
        title: 'STALLERGENES GREER vous souhaite une année 2021 sous le signe de la santé !',
        image: './assets/images/magazines/2021.jpg',
        description: "Les personnes allergiques peuvent prendre des dispositions pour minimiser les contacts directs avec les allergènes : Éviter les promenades en nature en cas d'alerte pollinique; Laver ses cheveux et ses vêtements après une sortie à l’extérieur; Aérer la maison tôt le matin et tard le soir... ",
      }
    ];
  }
}
