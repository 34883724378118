import { Component, OnInit } from '@angular/core';

import { HeaderComponent } from '../../../components/header/header.component';
import { FooterComponent } from '../../../components/footer/footer.component';
import { MenusComponent } from '../../../components/menus/menus.component';
import { MenusInfosComponent } from '../../../components/menus-infos/menus-infos.component';
import {TrackingService} from '../../../services/tracking.service';
import { DisclaimerService } from '../../../services/disclaimer.service';

@Component({
  selector: 'oap-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  constructor(private disclaimer: DisclaimerService,private tracking: TrackingService) { }

  ngOnInit() {
      this.tracking.DataLayer('/app/documentation','Rubrique','Mes infos utiles','Documentations');

  }
    downloadDoc(url: string, titre: string, download:boolean) {
        var a = window.document.createElement("a");
        a.href = url;
        if (download){
            a.download = url;
        }
        a.target="_blank"
        a.click();
        if (download) {
            this.tracking.pushDataLayer('Contenus', 'Telechargement', titre);
        }
        else {
            this.tracking.pushDataLayer('Contenus', 'Vues', titre);

        }
    }
}
