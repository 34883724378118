import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { StorageService} from './storage.service';


@Injectable()
export class AuthenticationService {
  // private apiUrl: string;

  constructor(private _http: HttpClient, private _appConfig: AppConfigService,private storageService: StorageService) {
  }

  login(username: string, password: string) {
    const apiUrl = this._appConfig.config.servicesBaseUrl;
    return this._http.post<any>(apiUrl + `/auth/login`, { login: username, password: password })
        .pipe(map(tokens => {
            // login successful if there's a jwt token in the response
            //console.log("debug tokens : ");
            //console.log(tokens);
            if (tokens && tokens.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(tokens));
                localStorage.setItem('compte-expire', JSON.stringify(tokens.expired));
                localStorage.setItem('validation-cgu', JSON.stringify(tokens.validationCGU));
            }
            return tokens;
        }));
  }

  forgetPassword(email: string) {
    const apiUrl = this._appConfig.config.servicesBaseUrl;
    return this._http.post<any>(apiUrl + `/cpt/forgot-password`, { email: email })
        .pipe(map(data => {
            return data;
        }));
  }

  unregister(compteKey: number) {
    const apiUrl = this._appConfig.config.servicesBaseUrl;
    return this._http.post<any>(apiUrl + `/cpt/unregister/` + compteKey, { })
        .pipe(map(ret => {
            return ret;
        }));
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('patientId');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('validation-cgu');
      localStorage.removeItem('compte-expire');
      localStorage.removeItem('firstPatientId');
      localStorage.removeItem('patients');
      localStorage.removeItem('patientCurrent');
      localStorage.removeItem('doubleauthent');
      //console.log('** logout **');
  }
}

