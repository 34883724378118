import {Component, OnInit, HostListener} from '@angular/core';
import {FormBuilder, Validators, FormGroup, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AlertService} from '../../services/alert.service';
import {ErrorStateMatcher} from '@angular/material';
import {first} from 'rxjs/operators';
import {AppConfigService} from '../../services/app-config.service';
import {RECAPTCHA_URL} from '../../directives/recaptcha.directive';
import {OapErrorMessages, errorMessages, OapStandarErrorStateMatcher} from '../../modules/custom-validators/custom-validators.module';

import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import {environment} from '../../../environments/environment';
import {TrackingService} from '../../services/tracking.service';

@Component({
    selector: 'oap-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
    providers: [{
        provide: RECAPTCHA_URL,
        useValue: 'http://localhost:3000/validate_captcha'
    }]
})
export class ForgetPasswordComponent implements OnInit {
    forgetPasswordForm: FormGroup;
    submitted = false;

    matcher = new OapStandarErrorStateMatcher();
    errors = errorMessages;

    validation_messages = OapErrorMessages;

    siteKey: string;
    loading = false;

    constructor(
        private _appConfig: AppConfigService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private tracking: TrackingService) {
    }

    ngOnInit() {
        this.siteKey = this._appConfig.config.reCaptchaSiteKey;

        if (!environment.production && !environment.preprod) {
            this.siteKey = '6Levgq8UAAAAACarJKYgQEbcMphFaXlbUlThZkmS';
        }
        this.alertService.clear();

        this.forgetPasswordForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required,
                Validators.minLength(5),
                Validators.maxLength(256),
                Validators.email])],
            captcha: ['', Validators.required],
        });

        var element = document.getElementById("scrollWrapper");
        element.classList.add("wrapperLogged");
        this.tracking.DataLayer('/app/forget-password','Mot de passe oublié','','');

    }

    ngOnDestroy() {
        var element = document.getElementById("scrollWrapper");
        element.classList.remove("wrapperLogged");
    }

    // Executed When Form Is Submitted
    onFormSubmit(form: FormGroup) {
        this.loading = true;
        this.submitted = true;

        // stop here if form is invalid
        if (this.forgetPasswordForm.invalid) {
            return;
        }

        this.authenticationService.forgetPassword(form.controls.email.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Vous allez recevoir un nouveau mot de passe par mail.', true);
                    this.tracking.pushDataLayer('Mot de passe oublié','Click','Mot de passe oublié valide');
                    this.loading = false;
                    this.router.navigate(['/login']);
                },
                error => {
                    this.loading = false;
                    this.tracking.pushDataLayer('Mot de passe oublié','Click','Mot de passe oublié invalide');
                    this.alertService.error(error);
                });
    }

    @HostListener('window:keyup.enter', ['$event', 'undefined'])
    @HostListener('window:click', ['undefined', '$event'])
    onEnterOrClick(enterEvent, mouseEvent) {
        this.alertService.clear();
    }

}
