import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { Cgu } from '../../models/textes-legaux/cgu';
import { map } from 'rxjs/operators';
import { MentionsLegales } from '../../models/textes-legaux/MentionsLegales';

@Injectable({
  providedIn: 'root'
})
export class TextesLegauxService {
  private apiUrl: string;

  constructor(private _http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }
  
  getCgu(controller: string) {
    return this._http.get<Cgu>(this.apiUrl + '/'+ controller + '/cgu')
        .pipe(map(data => {
          return data;
        }));
  }

  getMentionsLegales(controller: string) {
    return this._http.get<MentionsLegales>(this.apiUrl + '/'+ controller + '/mentions-legales')
        .pipe(map(ges => {
            return ges;
        }));
  }

  validationCGU(compteKey: number, cgu: boolean, arm: boolean) {
    const apiUrl = this._appConfig.config.servicesBaseUrl;
    const data = {
      compteKey: compteKey, 
      cgu: cgu,
      arm: arm
    };
    return this._http.post<any>(apiUrl + `/cpt/valid-cgu`, data)
        .pipe(map(c => {
            return c;
        }));
  }

}
