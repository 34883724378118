import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {
    MatNativeDateModule,
    MAT_DATE_FORMATS,
    DateAdapter,
    MAT_DATE_LOCALE,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule
} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TextMaskModule} from 'angular2-text-mask';
//import { LazyLoadImageModule } from 'ng-lazyload-image';

import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {AlertComponent} from './directives/alert.component';
import {DisclaimerComponent} from './directives/disclaimer.component';
import {AuthGuard} from './guards/auth.guard';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {AlertService} from './services/alert.service';
import {AuthenticationService} from './services/authentication.service';
import {UserService} from './services/user.service';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {AppConfigService} from './services/app-config.service';
import {EditProfilComponent} from './components/edit-profil/edit-profil.component';
import {ForgetPasswordComponent} from './components/forget-password/forget-password.component';
import {PatientService} from './services/patient.service';
import {ReCaptchaDirective} from './directives/recaptcha.directive';
import {UploadModule} from './upload/upload.module';
import {UplodedDocumentComponent} from './components/uploded-document/uploded-document.component';
import {RoundPipe} from './helpers/round.pipe';
import {FormatLengthFilePipe} from './helpers/format.length.file.pipe';
import {ChangePasswordModule} from './modules/change-password/change-password.module';
import {AdminModule} from './modules/admin/admin.module';
import {GestionnaireModule} from './modules/gestionnaire/gestionnaire.module';
import {OapHtmlDownloadFile} from './helpers/oap-html-download-file';
import {UnregisterComponent} from './components/unregister/unregister.component';
import {TextesLegauxModule} from './modules/textes-legaux/textes-legaux.module';
import {ViewTextesLegauxComponent} from './modules/textes-legaux/view-textes-legaux/view-textes-legaux.component';
import {LoaderService} from './services/loader.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenusComponent } from './components/menus/menus.component';
import { TreatmentComponent } from './components/treatment/treatment/treatment.component';
import { AllergieComponent } from './components/treatment/allergie/allergie.component';
import { ItaComponent } from './components/treatment/ita/ita.component';
import { StaloralComponent } from './components/treatment/staloral/staloral.component';
import { ContreIndicationsComponent } from './components/treatment/contre-indications/contre-indications.component';
import { AdvicesComponent } from './components/treatment/advices/advices.component';
import { LinksComponent } from './components/infos/links/links.component';
import { DocumentationComponent } from './components/infos/documentation/documentation.component';
import { ContactsComponent } from './components/infos/contacts/contacts.component';
import { ToolsComponent } from './components/infos/tools/tools.component';
import { MenusTreatmentComponent } from './components/menus-treatment/menus-treatment.component';
import { MenusInfosComponent } from './components/menus-infos/menus-infos.component';
import { DisclaimerService } from './services/disclaimer.service';
import {TrackingService} from './services/tracking.service';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DoubleAuthentComponent } from './components/double-authent/double-authent.component';
import { MagazineComponent } from './components/magazine/magazine.component';
import { MagazineSingleComponent } from './components/magazine-single/magazine-single.component';


const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        //LazyLoadImageModule,
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatMomentDateModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatListModule,
        MatTooltipModule,
        MatSelectModule,
        MatMenuModule,
        MatDialogModule,
        UploadModule,
        ChangePasswordModule,
        AdminModule,
        GestionnaireModule,
        TextMaskModule,
        TextesLegauxModule
    ],
    declarations: [
        AppComponent,
        RoundPipe,
        FormatLengthFilePipe,
        AlertComponent,
        DisclaimerComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        EditProfilComponent,
        ForgetPasswordComponent,
        ReCaptchaDirective,
        UplodedDocumentComponent,
        UnregisterComponent,
        HeaderComponent,
        FooterComponent,
        MenusComponent,
        TreatmentComponent,
        AllergieComponent,
        ItaComponent,
        StaloralComponent,
        ContreIndicationsComponent,
        AdvicesComponent,
        LinksComponent,
        DocumentationComponent,
        ContactsComponent,
        ToolsComponent,
        MenusTreatmentComponent,
        MenusInfosComponent,
        DeliveryComponent,
        DoubleAuthentComponent,
        MagazineComponent,
        MagazineSingleComponent
    ],
    entryComponents: [UnregisterComponent, ViewTextesLegauxComponent], // Add the DialogComponent as entry component
    providers: [
        AuthGuard,
        AlertService,
        LoaderService,
        DisclaimerService,
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        AuthenticationService,
        UserService,
        PatientService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        OapHtmlDownloadFile,
        TrackingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
