import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatButtonModule, MatDialogModule, MatInputModule, MatGridListModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChangePasswordService } from './change-password.service';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule, 
    MatDialogModule, 
    FlexLayoutModule,
    MatGridListModule,
    MatIconModule,
    MatTooltipModule
  ],
  declarations: [DialogComponent, ChangePasswordComponent],
  exports: [DialogComponent, ChangePasswordComponent],
  entryComponents: [DialogComponent], // Add the DialogComponent as entry component
  providers: [ChangePasswordService]
})
export class ChangePasswordModule { }
