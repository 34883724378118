import {Component, OnInit, Renderer2} from '@angular/core';

import { HeaderComponent } from '../../../components/header/header.component';
import { FooterComponent } from '../../../components/footer/footer.component';
import { MenusComponent } from '../../../components/menus/menus.component';
import { MenusInfosComponent } from '../../../components/menus-infos/menus-infos.component';

import { DisclaimerService } from '../../../services/disclaimer.service';
import {TrackingService} from '../../../services/tracking.service';


@Component({
  selector: 'oap-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

  constructor(private disclaimer: DisclaimerService,
              public tracking: TrackingService) { }

  ngOnInit() {
      this.tracking.DataLayer('/app/links','Rubrique','Mes infos utiles','Liens');

  }
}
