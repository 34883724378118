import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { EditProfilComponent } from './components/edit-profil/edit-profil.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { AdmHomeComponent } from './modules/admin/adm-home/adm-home.component';
import { GestHomeComponent } from './modules/gestionnaire/gest-home/gest-home.component';

import { TreatmentComponent } from './components/treatment/treatment/treatment.component';
import { AllergieComponent } from './components/treatment/allergie/allergie.component';
import { ItaComponent } from './components/treatment/ita/ita.component';
import { StaloralComponent } from './components/treatment/staloral/staloral.component';
import { ContreIndicationsComponent } from './components/treatment/contre-indications/contre-indications.component';
import { AdvicesComponent } from './components/treatment/advices/advices.component';
import { LinksComponent } from './components/infos/links/links.component';
import { DocumentationComponent } from './components/infos/documentation/documentation.component';
import { ContactsComponent } from './components/infos/contacts/contacts.component';
import { ToolsComponent } from './components/infos/tools/tools.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DoubleAuthentComponent } from './components/double-authent/double-authent.component';
import { MagazineComponent } from './components/magazine/magazine.component';
import { MagazineSingleComponent } from './components/magazine-single/magazine-single.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'edit-profil', component: EditProfilComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'forget-password', component: ForgetPasswordComponent },
    { path: 'admin', component: AdmHomeComponent, canActivate: [AuthGuard] },
    { path: 'backoffice', component: GestHomeComponent, canActivate: [AuthGuard] },
    { path: 'treatment', component: TreatmentComponent, canActivate: [AuthGuard] },
    { path: 'allergie', component: AllergieComponent, canActivate: [AuthGuard] },
    { path: 'ita', component: ItaComponent, canActivate: [AuthGuard] },
    { path: 'staloral', component: StaloralComponent, canActivate: [AuthGuard] },
    { path: 'contre-indications', component: ContreIndicationsComponent, canActivate: [AuthGuard] },
    { path: 'advices', component: AdvicesComponent, canActivate: [AuthGuard] },
    { path: 'links', component: LinksComponent, canActivate: [AuthGuard] },
    { path: 'documentation', component: DocumentationComponent, canActivate: [AuthGuard] },
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard] },
    { path: 'tools', component: ToolsComponent, canActivate: [AuthGuard] },
    { path: 'delivery', component: DeliveryComponent, canActivate: [AuthGuard] },
    { path: 'double-authent', component: DoubleAuthentComponent, canActivate: [AuthGuard] },
    { path: 'magazine', component: MagazineComponent, canActivate: [AuthGuard] },
    { path: 'magazine/:slug', component: MagazineSingleComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
