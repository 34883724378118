import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'oap-magazine-single',
  templateUrl: './magazine-single.component.html',
  styleUrls: ['./magazine-single.component.scss']
})
export class MagazineSingleComponent implements OnInit {
  
  public article;

  constructor(private router: ActivatedRoute, private route: Router) {
  }

  ngOnInit() {
    this.setCurrentArticle();
  }

  setCurrentArticle() {
    const slug = this.router.snapshot.params.slug;
    const articles = this.getArticles()

    this.article = articles.find(article => article.slug == slug);
  }

  getArticles() {
    return [
      {
        slug: 'alertes-aux-pollen-ayez-les-bons-reflexes',
        category: 'Newsetter Juin 2020',
        title: 'Alertes aux pollens : ayez les bons réflexes !',
        image: './assets/images/magazines/alertes-aux-pollen-ayez-les-bons-reflexes.jpg',
        date: '23/06/2020',
        content: `<h2>Le masque : utile pour réduire l’exposition aux allergènes</h2>
            <p>En raison de la maladie COVID-19, le port du masque s’est démocratisé.<br>Or, ce dispositif sanitaire est un <strong>puissant allié</strong> dans la lutte contre les allergies.</p>
            <p>Dans un communiqué récent, la Fédération française d’allergologie (FFAL) conseille <strong>de porter systématiquement un masque</strong> pour diminuer le risque d’allergie respiratoire.</p>
            <p>Pensez à le mettre, notamment quand vous faites des activités extérieures<br>et êtes en contact avec des pollens allergisants. </p>
            <a class="mag-cta" target="_blank" href="https://mcusercontent.com/7e29ac1f6069dc760d2704b35/files/75860370-1992-4b7f-87bf-56e92506bb13/CP_FFAL_Allergies_et_port_du_masque_22.06.2020_1_.01.pdf">Lire le communiqué</a>
            
            <h2>Inscrivez-vous aux alertes polliniques</h2>
            <p class="text-img">
              <img src="./assets/images/magazines/inscription-alertes-polliniques.jpg" alt="Inscription aux alertes polliniques" />
              Pour mieux vous <strong>préparer aux périodes d’allergies saisonnières</strong> et pour déterminer la destination de vos futures vacances, inscrivez-vous aux alertes polliniques.
              <br><br>
              Cet outil développé en partenariat avec le Réseau National de Surveillance Aérobiologique (RNSA) permet de <strong>suivre les prévisions du risque allergique</strong> dans trois départements de votre choix.
              <br><br>
              En vous abonnant à ce service gratuit, vous recevrez les <strong>prévisions hebdomadaires</strong> par email.
              <br><br>
              Ainsi, vous pourrez consulter </strong>les niveaux de risque par type de pollen</strong> pour chacun des départements sélectionnés.
            </p>
            <a class="mag-cta" target="_blank" href="https://www.stallergenesgreer.fr/alertes-polliniques">Recevoir les alertes polliniques</a>
            <p class="clear"></p>
            <h2>Accédez aux alertes à l'aide d'une application</h2>
            <p>Les alertes polliniques sont aussi disponibles dans l'application <strong>Alertes Pollens</strong>.</p>
            <p>En la téléchargeant, vous profiterez d'autres fonctionnalités intéressantes comme :</p>
            <ul>
              <li>un outil de suivi des épisodes allergiques ;</li>
              <li>un tableau de bord pour gérer les symptômes ;</li>
              <li>la géolocalisation pour obtenir de l'information en temps réel.</li>
            </ul>
            <p><strong>Sources :</strong></p>
            <ul>
                <li>Animateur : Gérald Kierzek, médecin urgentiste et chroniqueur médical</li>
                <li>Intervenants : Pr Alain Didier, chef de service, pneumologue et allergologue au CHU de Toulouse, et le Dr Isabelle Bossé, allergologue à La Rochelle</li>
            </ul>
            <h2>Rediffusion du live Allergies : surtout ne pas les négliger en période de coronavirus !</h2>
            <p>Vous n’avez pas pu assister au webinar, Allergies : surtout ne pas les négliger en période de coronavirus, organisé par STALLERGENES GREER et Doctissimo ?</p>
            <p>Rendez-vous sur notre chaîne YouTube pour regarder la rediffusion de ce live consacré à la maladie COVID-19 et à l’importance de la prise en charge médicale en cas d’allergie.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/DguzaX0LzR4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <ul>
                <li>Animateur : Gérald Kierzek, médecin urgentiste et chroniqueur médical</li>
                <li>Intervenants : Pr Alain Didier, chef de service, pneumologue et allergologue au CHU de Toulouse, et le Dr Isabelle Bossé, allergologue à La Rochelle</li>
            </ul>
            <h2>Continuez à prendre vos traitements</h2>
            <p>En dépit de la crise sanitaire, il est <strong>important de poursuivre les traitements</strong> contre les allergies respiratoires (antihistaminiques, désensibilisation inhalateur).</p>
            <img src="./assets/images/magazines/traitements.jpg" alt="Continuez vos traitements" />
            <h3>Consultez l’allergologue en toute confiance</h3>
            <ul>
              <li>Vous pouvez renouveler votre traitement en vous rendant au cabinet médical.</li>
              <li>Des mesures d’hygiène strictes y sont appliquées.
                  <br>Une téléconsultation peut être proposée si le médecin considère que cette prise en charge est la plus appropriée.</li>
            </ul>
            `,
      },
      {
        slug: 'les-allergies-ne-prennent-pas-de-vacances',
        category: 'Newsetter Décembre 2020',
        title: 'Les allergies ne prennent pas de vacances !',
        image: './assets/images/magazines/les-allergies-ne-prennent-pas-de-vacances.jpg',
        date: '21/12/2020',
        content: `<h2>Les poils d’animaux : présents en toute saison</h2>
          <p>Vous avez un chat ou un chien à la maison ? Vous avez probablement constaté que sa fourrure laisse des traces sur le sol, le tapis et le canapé tout au long de l’année. Et au grand désarroi des personnes allergiques, ces chutes de poils sont accentuées en automne et en hiver, lors des périodes de mue.</p>
          <p><strong>Lutter contre l’allergie aux phanères d’animaux</strong></p>
          <p>En cas d’allergie, l'éviction est recommandée. Cette séparation peut être d’autant plus laborieuse que l’attachement à l’animal de compagnie est fort ou que des proches en possèdent.</p>
          <p>L’hiver est une période propice à une vie casanière. Alors, si vous cohabitez avec un chat ou un chien, appliquez ces quelques conseils pour éviter les désagréments liés à l’allergie aux phanères d’animaux : </p>
          <ul>
            <li>"Aérer quotidiennement l’intérieur de la maison ou de l’appartement.</li>
            <li>Passer l’aspirateur dans les pièces fréquentées par le compagnon à quatre pattes.</li>
            <li>Autant que possible, lui interdire l’accès aux chambres.</li>
            <li>Brosser et laver l’animal régulièrement. </li>
            <li>Idéalement, installer un purificateur d’air pour éliminer un maximum de poils qui peuvent rester en suspension dans l’air. "</li>
          </ul>
          <p>Si les symptômes persistent, demandez conseil à un allergologue qui pourra identifier l'allergène en cause et vous proposera la prise en charge thérapeutique qui vous sera la plus adaptée.</p>
          <p>Pour plus d’information, regardez la rediffusion du webinar « <a href="https://www.youtube.com/watch?v=a3Hsm8NiV1c" target="_blank"><strong>Ne laissez pas les allergies gâcher vos vacances</strong></a> », organisé par STALLERGENES GREER et Doctissimo, sur notre chaîne YouTube.</p>
          <p><strong>Sources :</strong></p>
          <ul>
            <li>Intervenants : Gérald Kierzek, médecin urgentiste/chroniqueur médical</li>
            <li>et Dr Jean-François Fontaine, allergologue en cabinet libéral et au CHU de Reims.</li>
          </ul>
          <p class="text-img">
            <img src="./assets/images/magazines/brochure_enfant_touffu_phanere-1.png" alt="Brochure enfant" />
            <strong>Si votre enfant est allergique aux phanères d’animaux,</strong> proposez-lui de lire la brochure explicative de STALLERGENES GREER.
            <br><br>
            Elle lui permettra de mieux comprendre l’<strong>allergie aux animaux</strong> tout en s’amusant. Cette démarche pédagogique et ludique est reconnue et distinguée par l'Association Française pour la Prévention des Allergies (AFPRAL).
          </p>
          <a class="mag-cta" target="_blank" href="https://www.stallergenesgreer.fr/sites/default/files/pdf/brochure_enfant_touffu_phanere.pdf">Découvre le monde de Touffu le phanère</a>
          `,
      },
      {
        slug: 'stallergenes-greer-vous-souhaite-une-annee-2021-sous-le-signe-de-la-sante',
        category: 'Newsetter Janvier 2021',
        title: 'STALLERGENES GREER vous souhaite une année 2021 sous le signe de la santé !',
        image: './assets/images/magazines/2021.jpg',
        date: '02/02/2021',
        content: `<h2>Astuces et conseils en cas d’alertes polliniques</h2>
          <p>Les personnes allergiques peuvent prendre des dispositions pour minimiser les contacts directs avec les allergènes :</p>
          <p class="text-img">
            <img src="./assets/images/magazines/stallergenes-greer.jpg" alt="Stallergenes Greer" />
            <ul>
              <li>Éviter les promenades en nature en cas d'alerte pollinique ;</li>
              <li>Laver ses cheveux et ses vêtements après une sortie à l’extérieur ;</li>
              <li>Aérer la maison tôt le matin et tard le soir.</li>
              <li><span>Suivre les prévisions du risque allergique toutes les semaines, en téléchargeant l’application &nbsp;<a target="_blank" href="https://www.stallergenesgreer.fr/alertes-pollens">Alertes Pollens</a> ou en s'inscrivant aux alertes polliniques sur le site Internet de &nbsp;<a target="_blank" href="https://www.stallergenesgreer.fr/alertes-polliniques">STALLERGENES GREER</a>.</span></li>
            </ul>
          </p>
          <h2 class="clear">Renouvellement du traitement de désensibilisation : n’oubliez pas de mettre à jour votre profil patient sur Ordo IZZY !</h2>
          <p class="text-img">
            <img src="./assets/images/magazines/ginso.svg" alt="Ginso" />

            Si vous avez reçu une nouvelle prescription ou un renouvellement de votre <strong>traitement d’immunothérapie allergénique (ITA)</strong>, nous vous invitons à <strong>mettre à jour votre dossier patient</strong> dans la rubrique <a href="https://ordoizzy.stallergenesgreer.com/app/login?returnUrl=%2F">mon profil</a> sur le site <a href="https://ordoizzy.stallergenesgreer.com/app/login?returnUrl=%2F">Ordo IZZY</a>.
          </p>
          <a class="mag-cta" href="https://ordoizzy.stallergenesgreer.com/app/login?returnUrl=%2F">Connectez-vous</a>
          <p class="clear">Avec votre identifiant (adresse mail) et votre mot de passe. 
          <br>Si vous avez oublié votre mot passe, cliquez sur « mot de passe oublié » afin de le réinitialiser.</p>
          <p>Dans votre profil patient, déposez vos documents à jour :</p>
          <ul>
            <li>Attestation de Sécurité Sociale</li>
            <li>Mutuelle en cours de validité</li>
            <li>Nouvelle ordonnance, (si celle-ci n’a pas déjà été télétransmise par votre médecin traitant)</li>
          </ul>
          <p>Pour en faciliter le traitement, nous vous remercions de faire attention à ne pas nous envoyer vos documents en double via un autre canal (mail, fax, courrier).</p>
          <p>Cette mise à jour vous garantit le bon traitement de votre ordonnance et permet de définir le montant de votre traitement qui sera pris en charge par la sécurité sociale et par votre mutuelle selon les modalités de votre contrat et l’éventuel reste à charge.</p>
          <p><a href="https://ordoizzy.stallergenesgreer.com/app/login?returnUrl=%2F"><strong>L’équipe Ordo IZZY</strong></a> établit ensuite une facturation conforme à votre situation et s’assure de la mise en préparation de votre traitement pour une livraison à votre domicile.</p>
          <h2>Rediffusion du webinaire Asthme, allergies et logement : les bons gestes</h2>
          <img src="./assets/images/magazines/gjnlp.jpeg" alt="Webinaire Asthme, allergies et logement" />
          <p>En décembre dernier, STALLERGENES GREER et Asthme & Allergies ont organisé une webconférence sur le thème de la <strong>pollution intérieure</strong>, animée par <strong>Dr Jean-Marie Nguyen</strong>, allergologue, et <strong>Mr Dorian Chérioux</strong>, patient asthmatique et allergique.</p>
          <p>À cette occasion, <strong>Mme Chrisbelle Speyer</strong>, Conseillère Médicale en Environnement Intérieur, a répondu aux questions des participants et a donné des conseils pratiques pour limiter l’exposition à ces allergènes.</p>
          <a class="mag-cta" target="_blank" href="https://www.facebook.com/213725725363485/videos/860276007846435">Accéder à la rediffusion</a>
        `,
      }
    ];
  }

}
