import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { MatDialog, MatTableDataSource, MatSort, Sort, MatIconRegistry, MatSortable, MatSlideToggleChange, MatPaginator, PageEvent } from '@angular/material';
import { DialogComponent } from '../../change-password/dialog/dialog.component';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { regExps, OapStandarErrorStateMatcher, errorMessages, OapErrorMessages } from '../../custom-validators/custom-validators.module';
import { SearchElement, GestionnaireService, ProcessDocument } from '../gestionnaire.service';
import { first, tap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '../../../upload/upload.service';
import { DatePipe } from '@angular/common';
import { DetailPatientComponent } from '../detail-patient/detail-patient.component';
import { OapHtmlDownloadFile } from '../../../helpers/oap-html-download-file';
import { AuthToken } from '../../../models/authToken';
import { Gestionnaire } from '../../../models/gestionnaire';
import { CommentaireSurPatientComponent } from '../commentaire-sur-patient/commentaire-sur-patient.component';

export interface SearchResultData {
  patient_key: number;
  patient: string;
  date_naissance: Date;
  code_medecin: string;
  nom_medecin: string;
  numero_ordonnance: number;
  date_ordonnance: Date;
  date_depot: Date;
  type_document: string;
  nom_document: string;
  mime_type_document: string;
  infos_document: InfoDocumentData;
	documentTraite: Boolean;
  nomGestionnaire: string;
  avecCommentaire: boolean;
}

export interface InfoDocumentData {
  document_key: number;
  type_document: string;
  nom_document: string;
  mime_type_document: string;
}


@Component({
  selector: 'oap-gest-home',
  templateUrl: './gest-home.component.html',
  styleUrls: ['./gest-home.component.scss']
})
export class GestHomeComponent implements OnInit {
  searchForm: FormGroup;
  submitted = false;
  authToken: AuthToken;
  currentGestionnaire: Gestionnaire;

  displayedColumns: string[] = ['patient', 'date_naissance', 'code_medecin', 'nom_medecin', 'numero_ordonnance', 'date_ordonnance', 'date_depot', 'type_document', 'nom_document', 'actions', 'documentTraite', 'nomGestionnaire'];
  dataSource: MatTableDataSource<SearchResultData>;
  loading = false;
  results: SearchResultData[] = [];

  matcher = new OapStandarErrorStateMatcher();
  errors = errorMessages;
  validation_messages = OapErrorMessages;

  selection = new SelectionModel<SearchResultData>(true, []);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('myDiv') myBut: ElementRef;
  
  // information pour le paginator
  paginatorTotalLength = 100;
  pageSize = 8;
  pageOffset = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [4, 8, 12, 16];
  numColTri:number = 2; // numéro de la colonne à trié
  directionTri:string = 'asc'; // asc ou desc
  viewDocumentAllow = true; // autorise la vue des documents 

  // MatPaginator Output
  pageEvent: PageEvent;

  tooltip_view_document="Voir le document";
  tooltip_download_document="Télécharger le document";
  tooltip_detail_patient="Informations patient";
  tooltip_comments="Commentaires";

  label_silde_doc_traite="Afficher tous les documents";
  label_silde_patient_document="Afficher tous les patients";  
  
  avecPatientSansDocument

  constructor( public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private gestionnaireService: GestionnaireService,
    private messageService: MessageService,
    private uploadService : UploadService,
    private oapHtmlDownloadFile: OapHtmlDownloadFile,
    private iconRegistry: MatIconRegistry, 
    private sanitizer: DomSanitizer) { 
      iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-remove_red_eye-24px.svg'));
      iconRegistry.addSvgIcon('saveAs', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-save_alt-24px.svg'));
      iconRegistry.addSvgIcon('pageview', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-pageview-24px.svg'));
      iconRegistry.addSvgIcon('without_comments', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-description-24px.svg'));
      iconRegistry.addSvgIcon('with_comments', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/baseline-description-24px.svg'));

      this.authToken = JSON.parse(localStorage.getItem('currentUser'));
      // console.log(' id :' + this.authToken.id);

      if (this.authToken.type === "T") {
        this.viewDocumentAllow = false;
      }
  }

  ngOnInit() {
    this.messageService.sendMessage('page-gestionnaire');

    this.gestionnaireService.getGestionnaireByCptKey(this.authToken.id).pipe(first()).subscribe( ges => {
      if (ges) {
        let gestionnaires: Gestionnaire[] = ges;
        this.currentGestionnaire = gestionnaires[0];
        // console.log(' gestionnaireKey :' + this.currentGestionnaire.gestionnaireKey);
      }
    });

    var date = new Date();
    date.setDate(date.getDate() - 10);
    var datePipe = new DatePipe('en-US');
    const nowMinus10 = datePipe.transform(date, 'yyyy-MM-dd')

    this.searchForm = this.formBuilder.group({
      lastName : ['', Validators.maxLength(40)],
      firstName : ['', [Validators.maxLength(40),
        Validators.pattern(regExps.onlyLettersAccent)]],
      numeroSS : ['', Validators.maxLength(15)],
      telPatient : ['', Validators.maxLength(10)],
      codeMedecin: ['', Validators.maxLength(40)],
      nomMedecin: ['', Validators.maxLength(40)],
      numeroOrdonnance: ['', Validators.maxLength(40)],
      dateOrdonnance : ['', ],
      dateDepotDu : [nowMinus10 , ],
      dateDepotAu : ['', ],
      avecPatientSansDocument: ['', ],
      avecDocumentTraite: ['', ]
    });

    this.onFormSubmit(this.searchForm);
  }

  ngAfterViewInit() {
    const compteExpire = JSON.parse(localStorage.getItem('compte-expire'));
    if(compteExpire === 'true') {
      let dialogRef = this.dialog.open(DialogComponent, 
        { disableClose: true,
            data: { expired: true } 
      });
      localStorage.setItem('compte-expire', "false");

      this.dataSource.sort = this.sort;

    }

  }

  onFormSubmit(form: FormGroup) {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) {
        return;
    }
    
    var datePipe = new DatePipe('en');

    const searchElement: SearchElement = {
      nom: form.controls.lastName.value,
      prenom: form.controls.firstName.value,
      numeroSS: form.controls.numeroSS.value,
      telPatient: form.controls.telPatient.value,
      codeMedecin: form.controls.codeMedecin.value,
      nomMedecin: form.controls.nomMedecin.value,
      numeroOrdonnance: form.controls.numeroOrdonnance.value,
      dateOrdonnance: datePipe.transform(this.searchForm.controls.dateOrdonnance.value, 'yyyy-MM-dd'),
      dateDepotDu: datePipe.transform(form.controls.dateDepotDu.value, 'yyyy-MM-dd'),
      dateDepotAu: datePipe.transform(form.controls.dateDepotAu.value, 'yyyy-MM-dd'),
      avecPatientSansDocument: form.controls.avecPatientSansDocument.value,
      avecDocumentTraite: !form.controls.avecDocumentTraite.value,
      numColTri: this.numColTri, 
      limit: this.pageSize, // nombre de ligne dans une page
      pageOffset: 0, // décalage par rapport au début  
      directionTri: this.directionTri
    };

    // console.log(searchElement);

    this.doServiceSearch(searchElement);

  }

  private doServiceSearch(aSearchElement: SearchElement) {
    this.gestionnaireService.search(aSearchElement)
    .pipe(first())
    .subscribe(
        data => {
          
          this.pageOffset = data.pageOffset;
          this.pageSize = data.limit;
          this.paginatorTotalLength = data.totalElements;

          this.results = [];
          data.elements.forEach(element => {
            this.results.push(createSearchResultData(element));            
          });
          // console.log(" results count : " +  this.results.length );
          this.dataSource = new MatTableDataSource(this.results);

        },
        error => {
          console.error(error)
        },
        () => { this.loading = false; });
  }
  
  

  onPageEvent(event: PageEvent){
    this.pageOffset = event.pageIndex;
    this.pageSize = event.pageSize;
    //this.paginatorTotalLength = event.length
    
    var datePipe = new DatePipe('en');

    const searchElement: SearchElement = {
      nom: this.searchForm.controls.lastName.value,
      prenom: this.searchForm.controls.firstName.value,
      numeroSS: this.searchForm.controls.numeroSS.value,
      telPatient: this.searchForm.controls.telPatient.value,
      codeMedecin: this.searchForm.controls.codeMedecin.value,
      nomMedecin: this.searchForm.controls.nomMedecin.value,
      numeroOrdonnance: this.searchForm.controls.numeroOrdonnance.value,
      dateOrdonnance: datePipe.transform(this.searchForm.controls.dateOrdonnance.value, 'yyyy-MM-dd'),
      dateDepotDu: datePipe.transform(this.searchForm.controls.dateDepotDu.value, 'yyyy-MM-dd'),
      dateDepotAu: datePipe.transform(this.searchForm.controls.dateDepotAu.value, 'yyyy-MM-dd'),
      avecPatientSansDocument: this.searchForm.controls.avecPatientSansDocument.value,
      avecDocumentTraite: !this.searchForm.controls.avecDocumentTraite.value,
      numColTri: this.numColTri, 
      limit: event.pageSize, // nombre de ligne dans une page
      pageOffset: event.pageIndex, // décalage par rapport au début  
      directionTri: this.directionTri
    };

    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) {
        return;
    }

    this.doServiceSearch(searchElement);
  }

  sortData(sort: Sort) {

    // console.log('event sort' + sort.direction + ' active ' +sort.active);

    this.directionTri = sort.direction;

    switch (sort.active) {
      case 'patient': 
        this.numColTri = 2;
        break;
      case 'date_depot': 
        this.numColTri = 9;
        break;
      default:
        this.numColTri = 2; 
        break;
    }

    // console.log('launch sort');
    this.onFormSubmit(this.searchForm);

    // const data = this.dataSource.data;
    // if (!sort.active || sort.direction === '') {
    //   this.dataSource = new MatTableDataSource(data);
    //   return;
    // }

    // this.dataSource = new MatTableDataSource( data.sort((a, b) => {
    //   const isAsc = sort.direction === 'asc';
    //   switch (sort.active) {
    //     case 'patient': return compare(a.patient, b.patient, isAsc);
    //     default: return 0;
    //   }
    // }));
  }

  viewDocument(document: SearchResultData) {
    // console.log(' viewDocument document :' + document.infos_document.document_key);
    this.downloadFile(document, false);
  }

  downloadDocument(document: SearchResultData) {
    // console.log('downloadDocument document :' + document.infos_document.document_key);
    this.downloadFile(document, true);
  }

  detailPatient(document: SearchResultData) {
    // console.log('detailPatient patient :' + document.patient_key);
    let dialogRef = this.dialog.open(DetailPatientComponent, 
      { disableClose: false,
          data: { patientKey: document.patient_key } 
    });
  }

  commentaireSurPatient(document: SearchResultData) {
    let dialogRef = this.dialog.open(CommentaireSurPatientComponent, 
      { disableClose: true,
        data: { patientKey: document.patient_key },
        maxHeight: '600px',
        maxWidth: '800px',
        minHeight: '400px',
        minWidth: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed wit comment: ' + result);
      document.avecCommentaire = false;
      if(result) {
        document.avecCommentaire = true;
      } 
      this.classComment(document);
      this.iconComment(document);
    });    
  }

  classComment(document: SearchResultData) {
    if(document.avecCommentaire) {
      return 'opa-icon-with-comments mat-icon';
    } 
    return 'mat-icon';
  }

  iconComment(document: SearchResultData) {
    if(document.avecCommentaire) {
      return 'with_comments';
    } 
    return 'without_comments';
  }

  tooltipComment(document: SearchResultData) {
    if(document.avecCommentaire) {
      return 'Commentaires';
    } 
    return 'Aucun commentaires';
  }

  toggleDocumentTraite(document: SearchResultData) {
    console.log(' toggleDocumentTraite document :' + document.infos_document.document_key);
    document.documentTraite = !document.documentTraite;

    const processDocument: ProcessDocument = {
      documentKey: document.infos_document.document_key,
      gestionnaireKey: this.currentGestionnaire.gestionnaireKey,
      process: Boolean(document.documentTraite)
    };

    console.log(processDocument);

    this.gestionnaireService.process(processDocument)
    .pipe(first())
    .subscribe(
        data => {
          console.log(data);
          if (document.documentTraite) {
            document.nomGestionnaire = this.currentGestionnaire.prenom + ' ' + this.currentGestionnaire.nom;
          } else {
            document.nomGestionnaire = null;
          }
        },
        error => {
          console.error(error)
        });

  }

  clearSearchForm() {
    this.searchForm.patchValue(
      {
        lastName: null,
        firstName: null,
        numeroSS: null,
        telPatient: null,
        codeMedecin: null,
        nomMedecin: null,
        numeroOrdonnance: null,
        dateOrdonnance: null,
        dateDepotDu: null,
        dateDepotAu: null,
        avecPatientSansDocument: null,
        avecDocumentTraite: null,
      }
    )
  }


  private downloadFile(document: SearchResultData, forDownload: boolean) {
    console.log('downloadFile : ' + document.infos_document.document_key + ' - mime type : ' + document.infos_document.mime_type_document);
    this.uploadService.download(document.infos_document.document_key,  document.infos_document.mime_type_document).pipe(first())
      .subscribe (
        data => {
          var blob = new Blob([data], { type:  document.infos_document.mime_type_document });

          // nom du document forme si le médecin existe  Code_Medecin_N° Ordo_Type_document          
          // nom du document forme sinon Code_Medecin_N° Ordo_Type_document
          let nomDoc = document.infos_document.nom_document;
          if(document.code_medecin) {
            let idx = document.infos_document.nom_document.lastIndexOf('.');
            let extension = document.infos_document.nom_document.substring(idx);
            nomDoc = document.code_medecin + '_' + document.numero_ordonnance + '_' + document.infos_document.type_document + extension;
            nomDoc = nomDoc.replace(' ', '_');
          }

          this.oapHtmlDownloadFile.downloadFileOnNavigator(blob, nomDoc, forDownload);

          // if(forDownload) {
          //   var link = document.createElement("a");
          //   // Browsers that support HTML5 download attribute
          //   if (link.download !== undefined) 
          //   {
          //       var url = URL.createObjectURL(blob);
          //       link.setAttribute("href", url);
          //       link.setAttribute("download",  infoDocument.nom_document);
          //       link.style.visibility = 'hidden';
          //       document.body.appendChild(link);
          //       link.click();
          //       document.body.removeChild(link);
          //   }
          // } else {
          //   var url= window.URL.createObjectURL(blob);
          //   window.open(url,);
          // }
        },
        error => console.log("Error downloading the file : " + error),
        () => console.log('Completed file download.')
      );
  }

}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}



/** Builds and returns a new SearchResultData. */
function createSearchResultData(data: any): SearchResultData {

  const infoDoc : InfoDocumentData = {
    document_key: data.documentKey,
    type_document: data.typeDocument,
    nom_document: data.nomDocument,
    mime_type_document: data.documentMimeType
  }; 

  return {
    patient_key: data.patientKey,
    patient: data.nomPatient + '  ' + data.prenomPatient,
    date_naissance: data.dateNaissance,
    code_medecin: data.codeMedecin,
    nom_medecin: data.nomMedecin,
    numero_ordonnance: data.numeroOrdonnance,
    date_ordonnance: data.dateOrdonnance,
    date_depot: data.dateDepot,
    type_document: data.typeDocument,
    nom_document: data.nomDocument,
    mime_type_document : '',
    infos_document: infoDoc,
    documentTraite: data.documentTraite,
    nomGestionnaire: data.nomGestionnaire,
    avecCommentaire: data.avecCommentaire
  };
}