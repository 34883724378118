import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/user';
import { AppConfigService } from './app-config.service';
import { Register } from '../models/register';

@Injectable()
export class UserService {
    private apiUrl: string;

    constructor(private http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
    }

    getAll() {
        return this.http.get<User[]>(this.apiUrl + `/users`);
    }

    getById(id: number) {
        return this.http.get(this.apiUrl + `/users/` + id);
    }

    register(register: Register) {
        return this.http.post(this.apiUrl + `/ext/r`, register);
    }

    update(user: User) {
        return this.http.put(this.apiUrl + `/users/` + user.id, user);
    }

    delete(id: number) {
        return this.http.delete(this.apiUrl + `/users/` + id);
    }
}
