import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';
import { UploadedDocumentReduit } from '../models/uploadedDocumentReduit';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private apiUrl: string;

  constructor(private _http: HttpClient, 
      private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }

  getDocuments(patientKey: number, equivalence: string) {
    return this._http.get<UploadedDocumentReduit[]>(this.apiUrl + '/media/files/' + equivalence + '/pat/' + patientKey )
      .pipe(map(dto => {
        return dto;
      }));    
  }

  public upload(files: Set<File>, typeDocumentKey: string, compteKey: string, patientKey: number ): {[key:string]:Observable<number>} {
    // this will be the our resulting map
    const status = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', this.apiUrl + `/media/upl/` + typeDocumentKey + `/cpt/` + compteKey + `/pat/` + patientKey , formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this._http.request(req).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            // calculate the progress percentage
            const percentDone = Math.round(100 * event.loaded / event.total);
            // pass the percentage into the progress-stream
            progress.next(percentDone);
          } else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            progress.complete();
          } 
        },
        err => progress.error(err),
        () => progress.complete()
      );

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });
    // return the map of progress.observables
    return status;    
  }  

  delete(uploadDocumentKey: number) : Observable<String> {
    return this._http.delete<String>(this.apiUrl + `/media/files/` + uploadDocumentKey);
  }

  download(uploadDocumentKey: number, mimeType: string) {
    // var headers = new Headers();
    // headers.append('Accept', mimeType);
    // headers.append('responseType','arraybuffer' as 'json');
    return this._http.get( this.apiUrl + `/media/file/download/` + uploadDocumentKey, 
      {
        headers: { 'Accept': mimeType },
        responseType: 'blob' as 'json'
      })
      //.pipe(map((res:Response) => res
      .pipe(map(res => {
        return new Blob([res], { type: mimeType });
        }
      ));


  }
}
