import { Injectable } from "@angular/core";

@Injectable()
export class OapHtmlDownloadFile {

  downloadFileOnNavigator(aBlob: Blob, aFileName: string, aForDownload: boolean ) {

    var ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
    ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/),
    ieEDGE = navigator.userAgent.match(/Edge/g),
    ieVer=(ie ? ie[1] : (ie11 ? 11 : (ieEDGE ? 12 : -1)));

    if (ie && ieVer<10) {
      console.log("No blobs on IE ver<10");
      return;
    }
      
    if (ieVer>-1) {
      if(aForDownload) {
        window.navigator.msSaveBlob(aBlob, aFileName);
      } else {
        window.navigator.msSaveOrOpenBlob(aBlob, aFileName);
      }
    } else {             
      if(aForDownload) {
        var link = document.createElement("a");
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            var url = URL.createObjectURL(aBlob);
            link.setAttribute("href", url);
            link.setAttribute("download",  aFileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
      } else {
        var url= window.URL.createObjectURL(aBlob);
        window.open(url,);
      }
    }
  }    
}
