import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../../change-password/dialog/dialog.component';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { PatientService } from '../../../services/patient.service';
import { Observable } from 'rxjs';
import { CommentaireSurPatient } from '../../../models/CommentaireSurPatient';
import { tap, first } from 'rxjs/operators';

export interface CommentaireDialogData {
  patientKey: number;
}

@Component({
  selector: 'oap-commentaire-sur-patient',
  templateUrl: './commentaire-sur-patient.component.html',
  styleUrls: ['./commentaire-sur-patient.component.scss']
})
export class CommentaireSurPatientComponent implements OnInit {
  commentaireSurPatientForm: FormGroup;
  patientModel: Observable<CommentaireSurPatient>;
  submitted = false;

  oldCommentaire: String;

  constructor(public dialogRef: MatDialogRef<CommentaireSurPatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentaireDialogData,
    private formBuilder: FormBuilder,
    private patientService: PatientService) { }

  ngOnInit() {    
    // console.log(" dialog -> patientKey : " + this.data.patientKey );

    this.commentaireSurPatientForm = this.formBuilder.group({
      commentaire : ['', ],
    });

    this.patientModel = this.patientService.getCommentaireSurPatient(this.data.patientKey).pipe(
      tap(patCom => { this.commentaireSurPatientForm.patchValue(
        {
          commentaire: patCom.commentaire,
        });
        this.oldCommentaire = patCom.commentaire 
        })
    );
  }

  closeDialog() {
    if(this.oldCommentaire) {
      return this.dialogRef.close(true);
    } else {
      return this.dialogRef.close(false);
    }
  }  

  onFormSubmit(form: FormGroup) {
    //console.log('onsubmit : ' + form);
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.commentaireSurPatientForm.invalid) {
        return;
    }

    //form.controls.matchingEmails.value.email,
    const commentaireSurPatient :  CommentaireSurPatient = {
      patientKey: this.data.patientKey,
      commentaire: form.controls.commentaire.value
    }

    this.patientService.updateCommentaireSurPatient(this.data.patientKey, commentaireSurPatient) 
    .pipe(first())
    .subscribe(
      data => {
          // console.log(data)
          if(data.commentaire) {
            return this.dialogRef.close(true);
          } else {
            return this.dialogRef.close(false);
          }
      },
      error => {
          // console.log(error);
          return this.dialogRef.close(false);
      });
      
  }  
}
