import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import { DialogComponent } from '../../modules/change-password/dialog/dialog.component';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { UnregisterComponent } from '../../components/unregister/unregister.component';
import { AlertService } from '../../services/alert.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import {TrackingService} from '../../services/tracking.service';
import {PatientService} from '../../services/patient.service';
import {AuthToken} from '../../models/authToken';
import {isNull} from 'util';
import {first} from 'rxjs/operators';
import {Patient} from '../../models/patient';
import {LoaderService} from '../../services/loader.service';
import {isDefined} from '@angular/compiler/src/util';
import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'oap-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() headerType: string;

    public winwdowTop: number;
    public headerOpen: boolean;
    public menusOpen: boolean;
    public patientId: number;
    private subscribeScroll: Subscription;
    public patients;
    public patientsLength:number;
    public nameCurrentPatients;
    private currentPatients;
    public firstNamePatients: string;
    public firstIdPatients;
    currentUser: AuthToken;
    authToken: AuthToken;
    mapPatient: Map<number, Patient>;
    currentPatient: Patient;

    constructor(
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private loaderService: LoaderService,
        private scrollDispatcher: ScrollDispatcher,
        private cf: ChangeDetectorRef,
        public tracking: TrackingService, private patientService: PatientService, private storageService: StorageService) {
        this.winwdowTop = window.pageYOffset;
        this.headerOpen = false;
        this.menusOpen = false;
        this.patientService = patientService;
        this.authToken = JSON.parse(localStorage.getItem('currentUser'));

    }
    
  	ngOnInit() {
            if(!isNull(this.authToken)) {
                let patKey = parseInt(this.route.snapshot.paramMap.get('patientId'));
                this.patientService.getPatientsByCptKey(this.authToken.id).pipe(first()).subscribe(pat => {
                    if (pat) {
                        this.mapPatient = new Map<number, Patient>();
                        pat.forEach(p => {
                            this.mapPatient.set(p.patientKey, p);
                        });

                        this.patients = pat;
                        if (patKey) {
                            this.currentPatient = this.mapPatient.get(patKey);
                        } else {
                            this.currentPatient = this.patients[0];
                        }
                        this.patientId = this.currentPatient.patientKey;
                        this.firstNamePatients =this.patients[0].prenom + ' '+ this.patients[0].nom ;
                        this.firstIdPatients = this.patients[0].patientKey;

                        this.patientsLength = this.patients.length;
                        if (isNaN(parseInt(JSON.parse(localStorage.getItem('patientId'))))) {
                            localStorage.setItem('patientId', JSON.stringify(this.patientId)); // service ?
                            localStorage.setItem('firstPatientId', this.patientId.toString());
                            localStorage.setItem('patients', JSON.stringify(this.patients));
                            localStorage.setItem('patientCurrent', JSON.stringify(this.patients[0]));
                            localStorage.setItem('doubleauthent', false.toString());
                        }
                        this.currentPatients =JSON.parse(localStorage.getItem('patientCurrent'));
                        this.nameCurrentPatients=this.currentPatients.prenom+' '+ this.currentPatients.nom;
                        //this.loaderService.hideLoader();
                    }
                });
            }

        this.subscribeScroll = this.scrollDispatcher.scrolled().subscribe(x => {
            this.winwdowTop = window.pageYOffset;
            this.cf.detectChanges();
        });
    }

    ngOnDestroy() {
        this.subscribeScroll.unsubscribe();
    }

    logout(e) {
        // reset login status
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        this.tracking.pushDataLayer('Mon compte', 'Click', 'Deconnexion');
    }

    changePassword(e) {
        const dialogRef = this.dialog.open(DialogComponent,
            {
                disableClose: true,
                data: {expired: false}
            });
        this.tracking.pushDataLayer('Mon compte', 'Click', 'Modification');

    }

    unregister(e) {
        // console.log('*** unregister ***');
        const dialogRef = this.dialog.open(UnregisterComponent,
            {disableClose: false}
        );
        this.tracking.pushDataLayer('Mon compte', 'Click', 'Desinscription');

        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
            // console.log('result : ' + result);
            if (result) {
                this.authenticationService.logout();
                this.alertService.success('Merci de votre visite', true);
                this.router.navigate(['/login']);
            }
        });
    }

    toggleHeader() {
        this.headerOpen = !this.headerOpen;
    }

    toggleMenus() {
        this.menusOpen = !this.menusOpen;
    }

    openMenus() {
        this.menusOpen = true;
    }

    closeMenus() {
        this.menusOpen = false;
    }

    switchPatient(patientId: number, index : number){
        this.tracking.pushDataLayer('Switch client','Click','Changement realisé');
        this.nameCurrentPatients= this.patients[index].prenom+' '+ this.patients[index].nom;
        this.storageService.setItem('patientId', patientId.toString());
        localStorage.setItem('patientCurrent', JSON.stringify(this.patients[index]));
        this.menusOpen = false;
    }

}
