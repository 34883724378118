import { Component, OnInit } from '@angular/core';

import { HeaderComponent } from '../../../components/header/header.component';
import { FooterComponent } from '../../../components/footer/footer.component';
import { MenusComponent } from '../../../components/menus/menus.component';
import { MenusInfosComponent } from '../../../components/menus-infos/menus-infos.component';
import {TrackingService} from '../../../services/tracking.service';

@Component({
  selector: 'oap-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {

  constructor(private tracking: TrackingService) { }

  ngOnInit() {
      this.tracking.DataLayer('/app/tools','Rubrique','Mes infos utiles','Outils');
  }

}
