import {Component, OnInit, HostListener} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators, FormControl, NgForm, FormGroupDirective} from '@angular/forms';
import {first} from 'rxjs/operators';

import {AlertService,} from '../../services/alert.service';
import {AuthenticationService} from '../../services/authentication.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {MessageService} from '../../services/message.service';
import {OapStandarErrorStateMatcher, errorMessages, OapErrorMessages} from '../../modules/custom-validators/custom-validators.module';
import {MatDialog} from '@angular/material';
import {ViewTextesLegauxComponent} from '../../modules/textes-legaux/view-textes-legaux/view-textes-legaux.component';

import {HeaderComponent} from '../../components/header/header.component';
import {FooterComponent} from '../../components/footer/footer.component';
import {TrackingService} from '../../services/tracking.service';

@Component({
    selector: 'oap-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;

    matcher = new OapStandarErrorStateMatcher();
    errors = errorMessages;
    validation_messages = OapErrorMessages;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private tracking: TrackingService) {
    }

    ngOnInit() {
        //this.alertService.clear();

        this.loginForm = this.formBuilder.group({
            login: ['', Validators.compose([Validators.required, Validators.maxLength(256)])],
            mdp: ['', Validators.required]
        });

        //
        this.messageService.sendMessage('page-login');

        // reset login status
        this.authenticationService.logout();

        var element = document.getElementById("scrollWrapper");
        element.classList.add("wrapperLogged");

        this.tracking.DataLayer('/app','Homepage','','');

    }

    ngOnDestroy() {
        var element = document.getElementById("scrollWrapper");
        element.classList.remove("wrapperLogged");
    }

    // Executed When Form Is Submitted
    onFormSubmit(form: FormGroup) {
        //console.log(form);

        this.submitted = true;
        this.alertService.clear();

        // stop here if form is invalid
        if (form.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(form.controls.login.value, form.controls.mdp.value)
            .pipe(first())
            .subscribe(
                data => {

                    const authToken = JSON.parse(localStorage.getItem('currentUser'));
                    const typeCompte = authToken.type;
                    this.tracking.pushDataLayer('Acces portail','Click','Connection valide');

                    if (typeCompte === 'A') {
                        this.router.navigate(['/admin']);
                    } else if (typeCompte === 'G' || typeCompte === 'T') {
                        this.router.navigate(['/backoffice']);
                    } else {
                        var ret = this.cguOk();
                    }

                },
                error => {
                    this.alertService.error('Connexion impossible, identifiant et ou mot de passe incorrect.', true);
                    this.tracking.pushDataLayer('Acces portail','Click','Connection invalide');
                    this.loading = false;
                });

    }

    @HostListener('window:keyup.enter', ['$event', 'undefined'])
    @HostListener('window:click', ['undefined', '$event'])
    onEnterOrClick(enterEvent, mouseEvent) {
        this.alertService.clear();
    }


    cguOk() {
        const validationCGU = JSON.parse(localStorage.getItem('validation-cgu'));

        if (validationCGU === 'true') {
            let dialogRef = this.dialog.open(ViewTextesLegauxComponent,
                {
                    disableClose: true,
                    data: {typeTexte: 'cgu'}
                });

            dialogRef.afterClosed().subscribe(result => {
                //console.log('The dialog was closed');
                //console.log('result : ' + result);
                if (result === 'true') {
                    // cgu validé
                    localStorage.setItem('validation-cgu', 'false');
                    this.router.navigate(['/']);
                } else {
                    return null;
                }
            });
        } else {
            // cgu déjà validé
            this.router.navigate(['/']);
        }
    }

}
