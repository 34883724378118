import { Component, OnInit } from '@angular/core';

import { HeaderComponent } from '../../../components/header/header.component';
import { FooterComponent } from '../../../components/footer/footer.component';
import { MenusComponent } from '../../../components/menus/menus.component';
import { MenusInfosComponent } from '../../../components/menus-infos/menus-infos.component';
import {TrackingService} from '../../../services/tracking.service';

@Component({
  selector: 'oap-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(public tracking: TrackingService) { }

  ngOnInit() {
      this.tracking.DataLayer('/app/contacts','Rubrique','Mes infos utiles','Contact');
  }

}
