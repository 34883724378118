import {Component, OnInit, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective, FormControl} from '@angular/forms';
import {first} from 'rxjs/operators';

import {AlertService} from '../../services/alert.service';
import {UserService} from '../../services/user.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {MessageService} from '../../services/message.service';
import {RECAPTCHA_URL} from '../../directives/recaptcha.directive';
import {formatDate} from '@angular/common';
import {
    ConfirmValidParentMatcher,
    OapStandarErrorStateMatcher,
    regExps,
    errorMessages,
    OapErrorMessages,
    CustomValidatorsModule
} from '../../modules/custom-validators/custom-validators.module';
import {AppConfigService} from '../../services/app-config.service';

import {DateAdapter} from '@angular/material/core';
import {JJMMYYYY_FORMAT_PROVIDER} from '../../helpers/jjmmyyy-format.helper';

import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';

import {TrackingService} from '../../services/tracking.service';
import {DisclaimerService} from '../../services/disclaimer.service';
import {environment} from '../../../environments/environment';

export class RegisterErrorStateMatcherComponent implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'oap-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [{
        provide: RECAPTCHA_URL,
        useValue: 'http://localhost:3000/validate_captcha'
    }, ...JJMMYYYY_FORMAT_PROVIDER
    ]
})

export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;

    errors = errorMessages;

    register_validation_messages = OapErrorMessages;

    minDate = new Date(1900, 0, 1);
    maxDate: Date = new Date(formatDate(Date.now(), 'MM/dd/yyyy', 'en'));

    matcher = new OapStandarErrorStateMatcher();
    confirmValidParentMatcher = new ConfirmValidParentMatcher();

    siteKey: string;

    public mask = [/[1-4,7-8]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /[0-9A-B]/, ' ',
        /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
    public maskTel = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

    constructor(
        private _appConfig: AppConfigService,
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        private adapter: DateAdapter<any>,
        private messageService: MessageService,
        private tracking: TrackingService) {
    }

    ngOnInit() {
        this.alertService.clear();

        //
        this.messageService.sendMessage('page-register');
        this.siteKey = this._appConfig.config.reCaptchaSiteKey;

        if (!environment.production && !environment.preprod) {
            this.siteKey = '6Levgq8UAAAAACarJKYgQEbcMphFaXlbUlThZkmS';
        }
        this.adapter.setLocale('fr');

        this.registerForm = this.formBuilder.group({
            lastName: ['', [Validators.required,
                Validators.maxLength(40),
                Validators.pattern(regExps.onlyLetters)]],
            firstName: ['', [Validators.required,
                Validators.maxLength(40),
                Validators.pattern(regExps.onlyLettersAccent)]],
            birthday: ['', Validators.required],
            /*physicianName: ['', [Validators.required,
              Validators.maxLength(40),
              Validators.pattern(regExps.onlyLetters)]],*/
            mobile: ['', [CustomValidatorsModule.maskMinValidator(10),
                Validators.required]],
            matchingEmails: this.formBuilder.group({
                email: ['', [Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(256),
                    Validators.email]],
                emailConf: ['', Validators.required]
            }, {
                validator: (formGroup: FormGroup) => {
                    const ret = CustomValidatorsModule.areEqual(formGroup);
                    // console.log('validator matchingEmails : ' + ret);
                    return ret;
                }
            }),
            captcha: ['', Validators.required],
        });

        var element = document.getElementById("scrollWrapper");
        element.classList.add("wrapperLogged");

        this.tracking.DataLayer('/app/register','Inscription','','');

    }

    ngOnDestroy() {
        var element = document.getElementById("scrollWrapper");
        element.classList.remove("wrapperLogged");
    }

    @HostListener('window:keyup.enter', ['$event', 'undefined'])
    @HostListener('window:click', ['undefined', '$event'])
    onEnterOrClick(enterEvent, mouseEvent) {
        this.alertService.clear();
    }


    onSubmit(form: FormGroup) {
        // console.log(form);

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        let mobile: string = form.controls.mobile.value;
        mobile = mobile ? mobile.replace(/\s/g, '') : null;

        this.loading = true;

        const registerRequest = {
            firstName: form.controls.firstName.value,
            lastName: form.controls.lastName.value,
            birthday: form.controls.birthday.value,
            email: form.controls.matchingEmails.value.email,
            mobile: mobile,
            // physicianName: form.controls.physicianName.value
            physicianName: ''
        };

        this.userService.register(registerRequest)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Inscription réussie. Vous allez recevoir un mail de confirmation.', true);
                    this.tracking.pushDataLayer('Creation de compte','Click','Inscription valide');
                    this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error, true);
                    this.tracking.pushDataLayer('Creation de compte','Click','Inscription invalide');

                    console.error(error);
                    this.loading = false;
                });
    }
}
