import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { TypeDocument } from '../models/typeDocument';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypeDocumentService {
  private apiUrl: string;

  constructor(private http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }

  getAll() {
    return this.http.get<TypeDocument[]>(this.apiUrl + `/media/tdo`)
    .pipe(map(tdo => {
        return tdo;
    }));    
  }

  getById(typeDocumentKey: string) {
    return this.http.get<TypeDocument>(this.apiUrl + `/media/tdo/` + typeDocumentKey)
    .pipe(map(tdo => {
        return tdo;
    }));    
  }

  getByEquivalence(equivalence: string) {
    return this.http.get<TypeDocument[]>(this.apiUrl + `/media/tdo/equ/` + equivalence)
    .pipe(map(tdo => {
        return tdo;
    }));    
  }

}
