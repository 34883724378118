import {Component, OnInit, Renderer2} from '@angular/core';

import {HeaderComponent} from '../../../components/header/header.component';
import {FooterComponent} from '../../../components/footer/footer.component';
import {MenusComponent} from '../../../components/menus/menus.component';
import {MenusTreatmentComponent} from '../../../components/menus-treatment/menus-treatment.component';

import { DisclaimerService } from '../../../services/disclaimer.service';
import {TrackingService} from '../../../services/tracking.service';

@Component({
    selector: 'oap-contre-indications',
    templateUrl: './contre-indications.component.html',
    styleUrls: ['./contre-indications.component.scss']
})
export class ContreIndicationsComponent implements OnInit {
    public hideContent: boolean;
    public animCurrentlyPlaying: boolean;

    constructor(private renderer: Renderer2,
                private disclaimer: DisclaimerService,
                public tracking: TrackingService) {
    }

    ngOnInit() {
        this.hideContent = true;

        this.tracking.DataLayer('/app/contre-indications','Rubrique','Mon Traitement','Contre indications');
    }

    expandables(e,type) {
        e.stopPropagation();
        e.preventDefault();

        if (!this.animCurrentlyPlaying) {
            const $content = e.target.parentElement.children[2];
            this.animCurrentlyPlaying = true;

            if ($content.classList.contains('close')) {
                this.renderer.setStyle($content, 'height', $content.children[0].clientHeight + 'px');
                this.renderer.removeClass($content, 'close');
                this.renderer.addClass($content, 'open');
                this.tracking.pushDataLayer('Contenus','Vues',type);
            } else {
                this.renderer.setStyle($content, 'height', '0');
                this.renderer.removeClass($content, 'open');
                this.renderer.addClass($content, 'close');
            }

            this.animCurrentlyPlaying = false;
        }
    }

}
