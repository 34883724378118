import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DisclaimerService } from '../services/disclaimer.service';
import {TrackingService} from '../services/tracking.service';

@Component({
    selector: 'oap-disclaimer',
    templateUrl: 'disclaimer.component.html'
})

export class DisclaimerComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private disclaimerService: DisclaimerService,
                private tracking: TrackingService) { }

    ngOnInit() {
        this.subscription = this.disclaimerService.getMessage().subscribe(message => {
            if(message) {
              this.message = message;
            } else {
              this.message = null;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openExternal(url: string) {
        this.tracking.pushDataLayer('Lien sortant','Confirmation',url);
        this.message = null;
        this.disclaimerService.openExternal(url);
    }

    close() {
        this.message = null;
    }
}
