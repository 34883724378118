import {Component, OnInit} from '@angular/core';
import {PatientService} from '../../services/patient.service';
import {isNull} from 'util';
import {StorageService} from '../../services/storage.service';
import {TrackingService} from '../../services/tracking.service';
import {Router} from '@angular/router';


@Component({
    selector: 'oap-delivery',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

    public commandes;
    public commandesEnCours;
    public commandesAvenir;
    public commandesTerminees;
    public patientId: number;
    public identifiantSAP;
    public subscription;
    public pendingStatus;

    constructor(public patientService: PatientService, private storageService: StorageService,private router: Router, private tracking: TrackingService) {
        this.patientService = patientService;
        this.pendingStatus = [
            ['D00', 'D50'], // odonnance réceptionnée, étape 1
            ['D99'], // en cours de préparation, étape 2
            ['D01'], // expédition, étape 3
            ['D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'D11', 'D12', 'D13', 'D14', 'D15', 'D16', 'D20', 'D21', 'D22', 'D23', 'D24', 'D25', 'D26'], // livraison en cours, étape 4
            ['D17', 'D18', 'D19'], // livré, étape 5
        ];
    }

    ngOnInit() {
        if (localStorage.getItem('doubleauthent')== 'false' ){
            this.router.navigate(['/double-authent']);
        }
        this.identifiantSAP = JSON.parse(localStorage.getItem('patientCurrent')).identifiantSAP;
        if (!isNull(this.identifiantSAP)) {
            this.getInfoDelivery();
            this.subscription = this.storageService.watchStorage().subscribe((data: string) => {
                this.getInfoDelivery();
            });

        }
        else {
            this.patientId = -1;
        }
    }

    ngOnDestroy() {
        if(this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    private getInfoDelivery() {
        this.identifiantSAP = JSON.parse(localStorage.getItem('patientCurrent')).identifiantSAP;
        this.patientId = parseInt(localStorage.getItem('patientId'));
        this.patientService.getLivraisonsForPatient(this.patientId).subscribe(
            data => {
                this.commandes = data;
                this.commandesEnCours = this.commandes.en_cours;
                this.commandesAvenir = this.commandes.a_venir;
                this.commandesTerminees = this.commandes.terminees_ok;

                // compute current step for pending deliveries :
                let trackingOrders = [];
                for(let pendingDelivery of this.commandesEnCours) {
                    let currentStep = -1;
                    for(let i = 0; i < this.pendingStatus.length; i ++) {
                        if(this.pendingStatus[i].indexOf(pendingDelivery.codeEtatCommande) > -1) {
                            currentStep = i;
                        }
                    }
                    pendingDelivery.currentStep = currentStep;
                    let productsDuplicates = pendingDelivery.ligneDeCommande.map((line) => {
                        return line.produit 
                    });
                    //let products = productsDuplicates.filter((prod, index) => productsDuplicates.indexOf(prod) === index);
                    let products = productsDuplicates;
                    let labels = [
                        "Ordonnance réceptionnée",
                        "En cours de préparation",
                        "Expédition de votre traitement",
                        "Livraison en cours",
                        "Livraison effectuée"
                    ];
                    trackingOrders.push({
                        label: labels[currentStep],
                        products: products,
                        orderID: pendingDelivery.numeroCommande
                    });
                }
                if(trackingOrders.length) {
                    this.tracking.pushDataLayerDelivery('Livraison', 'Suivi de commande', trackingOrders);
                }
            },
            error => console.log(error)
        );
    }

    isEmpty(commande :any){
        return (commande == undefined || commande == null || commande.length == 0) ?  true : false;
    }


}
