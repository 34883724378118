import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oap-menus-treatment',
  templateUrl: './menus-treatment.component.html',
  styleUrls: ['./menus-treatment.component.scss']
})
export class MenusTreatmentComponent implements OnInit {

  @Input() active: string;

  private subscribeScroll: Subscription;
  public winwdowTop: number;

  constructor(
  	private scrollDispatcher: ScrollDispatcher,
  	private cf: ChangeDetectorRef) {
  	this.winwdowTop = window.pageYOffset;
  }

  ngOnInit() {
  		this.subscribeScroll = this.scrollDispatcher.scrolled().subscribe(x => {
            this.winwdowTop = window.pageYOffset;
            this.cf.detectChanges();
        });
  }

    ngOnDestroy() {
        this.subscribeScroll.unsubscribe();
    }

}
