import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule, MatButtonModule, MatDialogModule, MatGridListModule, MatIconModule, MatTooltipModule, MatNativeDateModule, MatDatepickerModule, MatTableModule, MatSortModule, MatCheckboxModule, MatSlideToggleModule, MatProgressSpinnerModule, MatPaginatorModule, MatPaginatorIntl, MatRadioModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ViewTextesLegauxComponent } from './view-textes-legaux/view-textes-legaux.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule, 
    MatDialogModule, 
    FlexLayoutModule,
    MatGridListModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  declarations: [ViewTextesLegauxComponent],
  providers: [],
  exports: [ViewTextesLegauxComponent],
  entryComponents: [ViewTextesLegauxComponent], // Add the DialogComponent as entry component

})

export class TextesLegauxModule { }
