import { Component, OnInit, Inject } from '@angular/core';
import { CommentaireDialogData } from '../../modules/gestionnaire/commentaire-sur-patient/commentaire-sur-patient.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import {TrackingService} from '../../services/tracking.service';

@Component({
  selector: 'oap-unregister',
  templateUrl: './unregister.component.html',
  styleUrls: ['./unregister.component.scss']
})
export class UnregisterComponent implements OnInit {

  compteKey: number;

  constructor(public dialogRef: MatDialogRef<UnregisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentaireDialogData,
    public authenticationService: AuthenticationService,
    private tracking: TrackingService) { }

  ngOnInit() {
    const authToken = JSON.parse(localStorage.getItem('currentUser'));
    this.compteKey = authToken.id;
  }

  closeDialog() {
      this.tracking.pushDataLayer('Desinscrire','Click','Abandon');
      return this.dialogRef.close(false);
  }  

  unregister() {
    this.tracking.pushDataLayer('Desinscrire','Click','Validation');

    this.authenticationService.unregister(this.compteKey)
    .pipe(first())
    .subscribe(
      data => {
        // console.log(data)
        return this.dialogRef.close(true);
      },
      error => {
        // console.log(error);
        return this.dialogRef.close(false);
      });
      
  }  

}
