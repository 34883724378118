import { MatPaginatorIntl } from "../../../node_modules/@angular/material";

export class MatPaginatorIntlFr extends MatPaginatorIntl {
  itemsPerPageLabel = 'Nombre de ligne par page';
  nextPageLabel     = 'Page suivante';
  previousPageLabel = 'Page précédente';
  firstPageLabel = 'Première page'
  lastPageLabel = 'Dernière page';

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return 'de ' + (startIndex + 1) + ' à ' + endIndex + ' sur un total de ' + length;
  };
}
