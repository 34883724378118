import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { map } from 'rxjs/operators';
import { GestionnaireUpdate } from '../../models/gestionnaireUpdate';
import { AdminResultData, EnableGestionnaireData, ResetPasswordGestionnaireData } from './adm-home/adm-home.component';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private apiUrl: string;

  constructor(private _http: HttpClient, private _appConfig: AppConfigService) {
      this.apiUrl = _appConfig.config.servicesBaseUrl;
  }

  findAll() {
    const apiUrl = this._appConfig.config.servicesBaseUrl;
    return this._http.post<any>(apiUrl + `/adm/all`, null)
        .pipe(map(c => {
            return c;
        }));
  }

  save(compteKey: number, gestionnaireKey: number, gestionnaire: GestionnaireUpdate) {
    return this._http.put<AdminResultData>(this.apiUrl + '/adm/cpt/'+compteKey.toString()+'/ges/' + gestionnaireKey.toString(), gestionnaire)
        .pipe(map(ges => {
            return ges;
        }));
  }

  add(gestionnaire: GestionnaireUpdate) {
    return this._http.post<AdminResultData>(this.apiUrl + '/adm/add', gestionnaire)
        .pipe(map(ges => {
            return ges;
        }));
  }

  enable(enableGestionnaire: EnableGestionnaireData) {
    return this._http.post<AdminResultData>(this.apiUrl + '/adm/enable', enableGestionnaire)
        .pipe(map(ges => {
            return ges;
        }));
  }

  resetPassword(resetPasswordGestionnaire: ResetPasswordGestionnaireData) {
    return this._http.post<AdminResultData>(this.apiUrl + '/adm/reset-password', resetPasswordGestionnaire)
        .pipe(map(ges => {
            return ges;
        }));
  }

}
