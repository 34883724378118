import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorFn, FormGroup, FormControl, FormGroupDirective, NgForm, ValidationErrors, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class CustomValidatorsModule { 

    /**
     * Validates that child controls in the form group are equal
     */
  static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
    const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
    const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value);
    return isValid ? null : { childrenNotEqual: true };
  }

  static areEqual(formGroup: FormGroup): ValidationErrors {
    let value;
    let valid = true;
    for (let key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        let control: FormControl = <FormControl>formGroup.controls[key];

        if (value === undefined) {
          value = control.value
        } else {
          if (value !== control.value) {
            valid = false;
            //console.log( value + " ?? " + control.value + " equals : " + valid);
            break;
          }
        }
      }
    }

    if (valid) {
      //console.log( value + " equals : " + valid);
      return null;
    }

    return {
      areEqual: false
    };
  }

  static numeroSecuriteSociale(formGroup: FormGroup): ValidationErrors {
    let value;
    let valid = true;
    for (let key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        let control: FormControl = <FormControl>formGroup.controls[key];

        if (value === undefined) {
          value = control.value
        } else {
          if (value !== control.value) {
            valid = false;
            //console.log( value + " ?? " + control.value + " equals : " + valid);
            break;
          }
        }
      }
    }

    if (valid) {
      //console.log( value + " equals : " + valid);
      return null;
    }

    return {
      areEqual: false
    };
  }

  static maskMinValidator(min: number): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && isNaN(control.value)) {
        let val: string = control.value
        // permet de supprimer les espaces contenu dans le mask mask
        // a voir pour les validator si on peut les conserver
        val = val ? val.replace((/\s|_/g), "") : null;
        if (val !== undefined && val.length < min) {
          return { 'maskMinLength': true };
        }
      }  
      return null;
    };
  }

  static maskMaxValidator(max: number): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && isNaN(control.value)) {
        let val: string = control.value
        // permet de supprimer les espaces contenu dans le mask mask
        // a voir pour les validator si on peut les conserver
        val = val ? val.replace((/\s|_/g), "") : null;
        if (val !== undefined && val.length > max) {
          return { 'maskMinLength': true };
        }
      }  
      return null;
    };
  }

  static checkNirKeyValidator(): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && isNaN(control.value)) {
        let val: string = control.value
        // permet de supprimer les espaces contenu dans le mask mask
        // a voir pour les validator si on peut les conserver
        val = val ? val.replace((/\s|_/g), "") : null;
        if (val !== undefined && val.length == 15) {
          if(!this.CalculCle(val)) {
            // console.log(" calcul clé KO")
            return { 'nirCleKO': true };
          }
        }
      }  
      return null;
    };
  }

  static CalculCle(numSS: string): boolean {
    if (numSS !== undefined && numSS.length == 15) {
      // pour la corse on remplace 2A par 19 et 2B par 18
      numSS = numSS ? numSS.replace((/2A/g), "19") : numSS;
      numSS = numSS ? numSS.replace((/2B/g), "18") : numSS;

      var numbers = new RegExp(/^[0-9]+$/);
      
      if(numbers.test(numSS))
      {
        let cle = +numSS.substring(13);
        let num = +numSS.substring(0, 13);
        // console.log("clé saisie : " + cle);
        let cleCalculee = 97 - (num % 97)
        // console.log("clé calculée : " + cleCalculee);
        if (cle == cleCalculee) {
          return true;
        } 
      }
      else
      {
        console.log("numSS " + numSS + " is NOT number");
      }      
      return false;
    }
    return false;
  }

}

export class OapStandarErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.parent.invalid && (control.dirty || control.touched);
  }
}

/**
* Collection of reusable RegExps
*/
export const regExps: { [key: string]: RegExp } = {
  onlyLetters: /^[a-zA-Z][a-zA-Z- ]*$/,
  onlyLettersAccent: /^[a-zA-Z][a-zA-Z- \u00C0-\u024F]*$/,
  onlyDigits: /^[0-9]*$/,
  zipCode: /^[0-9]{5}$/,
  password: /^(?=.*[0-9])[a-zA-Z0-9!.@#$%^&*]{7,15}$/,
  passwordComplex: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!.@#$%^&*]{7,15}$/
};

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
  fullName: 'Full name must be between 1 and 128 characters',
  email: 'Email must be a valid email address (username@domain)',
  confirmEmail: 'Email addresses must match',
  password: 'Password must be between 7 and 15 characters, and contain at least one number and special character',
  confirmPassword: 'Passwords must match',
  pattern: 'Ne doit contenir que des lettres'
};


export const OapErrorMessages: { [key: string]: any } = { 
  'login': [
    { type: 'required', message: 'L\'identifiant est obligatoire' },
    { type: 'maxlength', message: 'Maximum 256 caractères' }
  ],
  'identifiant': [
    { type: 'required', message: 'L\'identifiant est obligatoire' },
    { type: 'maxlength', message: 'Maximum 40 caractères' }
  ],
  'mdp': [
    { type: 'required', message: 'Le mot de passe est obligatoire' },
    { type: 'maxlength', message: 'Maximum 250 caractères' }
  ],
  'firstName': [
    { type: 'required', message: 'Votre prénom est obligatoire' },
    { type: 'minlength', message: 'Username must be at least 5 characters long' },
    { type: 'maxlength', message: 'Maximum 40 caractères' },
    { type: 'pattern', message: 'Ne doit contenir que des lettres' }
  ],
  'lastName': [
    { type: 'required', message: 'Votre nom est obligatoire' },
    { type: 'maxlength', message: 'Maximum 40 caractères' },
    { type: 'pattern', message: 'Ne doit contenir que des lettres' },
  ],
  'physicianName': [
    { type: 'required', message: 'Le nom de votre médecin est obligatoire' },
    { type: 'maxlength', message: 'Maximum 40 caractères' },
    { type: 'pattern', message: 'Ne doit contenir que des lettres' },
  ],
  'email': [
    { type: 'required', message: 'L\'adresse mail est obligatoire' },
    { type: 'minlength', message: 'L\'adresse mail doit avoir au moins 5 caractères' },
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' },
    { type: 'email', message: 'Votre adresse email doit être une adresse email valide' }
  ],
  'emailConf': [
    { type: 'required', message: 'Confirmation adresse mail est obligatoire' },
    { type: 'areEqualxx', message: 'Confirmation adresse mail ne correspond pas à l\'adresse mail.' }
  ],
  'matchingEmails': [
    { type: 'areEqual', message: 'Confirmation adresse mail ne correspond pas à l\'adresse mail.' }
  ],
  'rue': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'complement': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'codePostale': [
    { type: 'pattern', message: 'Doit contenir 5 chiffres' },
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'ville': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'mobile': [
    { type: 'required', message: 'Le numéro de téléphone portable est obligatoire' },
    { type: 'pattern', message: 'Ne doit contenir que des chiffres' },
    { type: 'maskMinLength', message: 'Vous devez saisir 10 chiffres.' }
  ],
  'telephoneFixe': [
    { type: 'pattern', message: 'Ne doit contenir que des chiffres' },
    { type: 'maskMinLength', message: 'Vous devez saisir 10 chiffres.' }
  ],
  'phone': [
    { type: 'pattern', message: 'Ne doit contenir que des chiffres' },
    { type: 'maskMinLength', message: 'Vous devez saisir 10 chiffres.' }
  ],
  'dateNaissance': [
    { type: 'pattern', message: 'Ne doit contenir que des chiffres' }
  ],
  'numeroSecuriteSociale': [
    { type: 'pattern', message: 'Ne doit contenir que des chiffres' },
    { type: 'maskMinLength', message: 'Vous devez saisir 15 caractères.' },
    { type: 'nirCleKO', message: 'Le numéro de sécurité sociale est erroné.' },
  ],
  'nomCPAM': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'nomMutuelle': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'mutuelleCodePrefectoral': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'mutuelleNumeroAdherent': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'mutuelleDateFinDroit': [
    { type: 'maxlength', message: 'Vous avez dépassé le nombre de caractères maximum autorisé.' }
  ],
  'matchingPassword': [
    { type: 'areEqual', message: 'Ne correspond pas au mot de passe saisie' }
  ],
  'changePasswordForm': [
    { type: 'areEqual', message: 'Ne correspond pas au mot de passe saisie' }
  ],
  'password': [
    { type: 'pattern', message: 'Doit contenir au moins une minuscule, une majuscule et un chiffre' },
    { type: 'minlength', message: 'Doit contenir au moins 7 caractères' },
    { type: 'maxlength', message: 'Doit contenir au maximum 15 caractères' },
    { type: 'required', message: 'Mot de passe obligatoire' }
  ],
  'confirmPassword': [
    { type: 'required', message: 'Mot de passe obligatoire' }
  ],
  'prenomAssure': [
    { type: 'minlength', message: 'Username must be at least 5 characters long' },
    { type: 'maxlength', message: 'Maximum 40 caractères' },
    { type: 'pattern', message: 'Ne doit contenir que des lettres' }
  ],
  'nomAssure': [
    { type: 'maxlength', message: 'Maximum 40 caractères' },
    { type: 'pattern', message: 'Ne doit contenir que des lettres' },
  ],
  'dateNaissanceAssure': [
    { type: 'pattern', message: 'Ne doit contenir que des chiffres' }
  ],
  'code': [
      { type: 'required', message: 'Le code temporaire est obligatoire' }
  ]
};
