import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestHomeComponent } from './gest-home/gest-home.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule, MatButtonModule, MatDialogModule, MatGridListModule, MatIconModule, MatTooltipModule, MatNativeDateModule, MatDatepickerModule, MatTableModule, MatSortModule, MatCheckboxModule, MatSlideToggleModule, MatProgressSpinnerModule, MatPaginatorModule, MatPaginatorIntl, MatRadioModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DetailPatientComponent } from './detail-patient/detail-patient.component';
import { TextMaskModule } from 'angular2-text-mask';
import { OapHtmlDownloadFile } from '../../helpers/oap-html-download-file';
import { MatPaginatorIntlFr } from '../../helpers/mat-paginator-intl-fr';
import { CommentaireSurPatientComponent } from './commentaire-sur-patient/commentaire-sur-patient.component';
import { CommonBoModuleModule } from '../common-bo-module/common-bo-module.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule, 
    MatDialogModule, 
    FlexLayoutModule,
    MatGridListModule,
    MatIconModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    TextMaskModule,
    CommonBoModuleModule
  ],
  declarations: [GestHomeComponent, DetailPatientComponent, CommentaireSurPatientComponent],
  providers: [OapHtmlDownloadFile, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr}],
  exports: [DetailPatientComponent, CommentaireSurPatientComponent],
  entryComponents: [DetailPatientComponent, CommentaireSurPatientComponent], // Add the DialogComponent as entry component

})
export class GestionnaireModule { }
