import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

    loaderContainer: HTMLElement;

    constructor() {

    }

    initialize(element: HTMLElement) {
        this.loaderContainer = element;
    }

    showLoader() {
        document.body.style.overflowY = 'hidden';
        this.loaderContainer.style.visibility = 'visible';
    }

    hideLoader() {
        this.loaderContainer.style.visibility = 'hidden';
        document.body.style.overflowY = 'auto';
    }

}
