import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatIconRegistry } from '@angular/material';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { regExps, errorMessages, OapErrorMessages, OapStandarErrorStateMatcher, ConfirmValidParentMatcher, CustomValidatorsModule } from '../../custom-validators/custom-validators.module';
import { ChangePasswordService } from '../change-password.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import {TrackingService} from '../../../services/tracking.service';

export interface DialogData {
  expired: boolean;
}

@Component({
  selector: 'oap-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  changePasswordForm: FormGroup;
  showCancelButton = true;
  submitted = false;
  
  errors = errorMessages;
  validation_messages = OapErrorMessages;
  matcher = new OapStandarErrorStateMatcher();
  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  inputTypePassword="password";
  tooltip_password="Voir le mot de passe";
  inputTypeConfirmPassword="password";
  tooltip_confirm_password="Voir le mot de passe";
  
  showInformation=false;
  info="C'est votre première connexion, veuillez changer de mot de passe.";

  compteKey: number;

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private iconRegistry: MatIconRegistry, 
    private sanitizer: DomSanitizer,
    private tracking: TrackingService) {
      iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-remove_red_eye-24px.svg'));
      iconRegistry.addSvgIcon('saveAs', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/outline-save_alt-24px.svg'));
    }


  ngOnInit() {
    // console.log(" dialog -> expired? : " + this.data.expired );

    const authToken = JSON.parse(localStorage.getItem('currentUser'));
    this.compteKey = authToken.id;
    //console.log(' compteKey :' + parseInt(authToken.id));

    //console.log("debug dialog : ");
    //console.log(this.data);
    if(this.data.expired) {
      this.showInformation = true;
      this.showCancelButton = false;
    }

    this.changePasswordForm = this.formBuilder.group({
      matchingPassword : this.formBuilder.group({
        password: ['', [Validators.required, 
          Validators.pattern(regExps.password), 
          Validators.minLength(7),
          Validators.maxLength(15)
          ]],
        confirmPassword: ['', Validators.required]
        }, {
         validator: (formGroup: FormGroup) => {
           var ret =  CustomValidatorsModule.areEqual(formGroup);
           //console.log("validator matchingPassword : " + ret);
          return ret;
        }
      })
    });
  }

  toggleViewPassword() {
    //console.log("click icic");
    if(this.inputTypePassword == "password") {
      this.inputTypePassword = "text";
      this.tooltip_password="Cacher le mot de passe";
    } else {
      this.inputTypePassword = "password";
      this.tooltip_password="Voir le mot de passe";
      
    }
  }

  toggleViewConfirmPassword() {
    if(this.inputTypeConfirmPassword == "password") {
      this.inputTypeConfirmPassword = "text";
      this.tooltip_confirm_password="Cacher le mot de passe";
    } else {
      this.inputTypeConfirmPassword = "password";
      this.tooltip_confirm_password="Voir le mot de passe";
    }
  }

  onSubmit(form: FormGroup) {
    //console.log('onsubmit : ' + form);
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
        return;
    }

    //form.controls.matchingEmails.value.email,

    this.changePasswordService.changePassword(this.compteKey, form.controls.matchingPassword.value.password) 
    .pipe(first())
    .subscribe(
      data => {
          console.log(data)
      },
      error => {
          console.log(error);
      });

    this.tracking.pushDataLayer('Mot de passe','Click','Validation');

    return this.dialogRef.close();
  }
    closeTracking(){
      this.tracking.pushDataLayer('Mot de passe','Click','Abandon');
  }
  closeDialog() {
    return this.dialogRef.close();
  }  
}
